.ete {
  &.sa_top {
    .about__column-main-first {
      width: fit-content;
    }

    .about__row-main-text:nth-child(2) {
      justify-content: flex-end;

      @media (max-width: 1030px) {
        justify-content: center;
      }
    }

    .about__text-main-secondary {
      margin-left: 0;
    }
  }

  &_stage-header {
    display: flex;
    gap: 24px;
  }

  &_stages-point {
    display: flex;
    gap: 24px;

    &-bullet path {
      fill: var(--primary-white);
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &.about__casses-container {
    @media (max-width: 576px) {
      padding-top: 0;
    }
  }
}

.end-to-end {
  &_wwyg {
    padding: 80px 0;
  }

  &__services {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1px;
      background-color: var(--grey-900);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 50%);
    }

    @media (max-width: 576px) {
      display: flex;
      gap: 5vw;
      transition: all 0.2s ease-in-out;
      background: transparent;

      &-wrap {
        overflow: hidden;
        padding: 0 5vw 40px;
      }
    }
  }

  &__service {
    user-select: none;
    height: 430px;
    position: relative;
    background: var(--primary-white);

    display: flex;
    flex-direction: column;
    padding: 24px 2.9vw;

    &,
    &-number,
    &-title,
    &-text {
      transition: all 0.5s ease-in-out;
    }

    &:not(:last-of-type) {
      border-right: 1px solid var(--grey-900);
    }

    &:hover {
      cursor: pointer;
      background: var(--grey-900);

      .end-to-end__service-number,
      .end-to-end__service-title,
      .end-to-end__service-text {
        display: block;
        color: var(--primary-white);
      }

      .link-arrow-black {
        display: none;
      }
    }

    &-number,
    &-title,
    &-text {
      margin: 0;
    }

    &-number,
    &-title {
      @include subtitle-3;
      @include semi-bold;

      margin-bottom: 52px;
    }

    &-title {
      padding-right: 10px;
    }

    &-text {
      color: var(--primary-white);
    }

    .link-arrow-black {
      position: absolute;
      bottom: 24px;
      right: 2.9vw;
    }

    @media (max-width: 1280px) {
      height: 248px;

      &:not(:last-of-type) {
        border-right: none;
      }

      &-number,
      &-title {
        margin-bottom: 26px;
      }
    }

    @media (max-width: 768px) {
      padding: 24px 40px;

      .link-arrow-black {
        top: 24px;
        right: 40px;
      }
    }

    @media (max-width: 576px) {
      flex: 0 0 90vw;
      border-right: 1px solid var(--grey-900) !important;
      border-left: 1px solid var(--grey-900);

      &-text {
        display: block;
      }

      &:hover {
        .link-arrow-black {
          display: block;

          path {
            fill: var(--primary-white);
          }
        }
      }
    }
  }
}
