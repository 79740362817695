@mixin clear-button {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sa_top {
  @media (max-width: 576px) {
    position: relative;
    overflow: hidden;

    .about__row-image-first-mobile {
      position: absolute;
      bottom: -200px;
    }
  }

  .about__column-main-first {
    padding-top: 30vh;
    margin-left: 56px;
    width: 60%;

    @media (max-width: 1366px) {
      width: 60%;
    }

    @media (max-width: 1030px) {
      box-sizing: border-box;
      margin: auto;
      padding-top: 18vh;
      padding-left: 15vw;
      padding-right: 15vw;
      text-align: center;
      width: 100%;

      .about__row-main-text {
        justify-content: center;
      }
    }

    @media (max-width: 576px) {
      padding-left: 5vw;
      padding-right: 5vw;

      .about__row-main-text {
        padding: 0 5vw;
      }
    }
  }

  .about__text-main {
    @include font-manrope;
    @include h2;
    @include medium;

    @media (max-width: 1281px) {
      @include font-manrope;
      @include h2;
      @include medium;
      font-style: normal;
      font-size: 56px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #fdfdfd;
    }
  }

  .about__row-main-text-2 {
    width: 885px;

    @media (max-width: 1500px) {
      width: 740px;
    }
    @media (max-width: 1030px) {
      width: auto;
    }
  }

  .about__text-main-secondary {
    width: 70%;
    margin-left: 30%;
    text-align: justify;

    @media (max-width: 1030px) {
      font-size: 1rem;
      width: 100%;
      margin-left: auto;
      text-align: center;
    }

    &-desktop-only {
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  .about__g2-main {
    width: 166px;
  }

  @media screen and (min-width: 1280px) {
    .about__to-bottom-second {
      flex: 1;
    }
  }
}

.sa_hire-specialists {
  @media (max-width: 1030px) {
    box-sizing: border-box;
    padding: 60px 5vw;

    .about__row-talk-container,
    .about__text-talk-container,
    .about__text-talk-container-transparent,
    .about__text-talk-container-secondary,
    .about__text-talk-container-secondary-transparent {
      margin: 0;
    }

    .about__text-talk-container,
    .about__text-talk-container-secondary,
    .about__text-talk-container-secondary-transparent {
      display: block;
      @include h2;
      @include medium;
    }

    .about__row-talk-container,
    .about__talk-container-subtext {
      @include body-2;
      width: 100%;
    }

    .about__button-talk-container,
    .about__button-talk-container-secondary {
      height: 56px;
    }
  }
}

.staff-augmentation {
  &__section {
    padding: 100px 5vw;

    &,
    * {
      box-sizing: border-box;
    }

    &.sa_remote-teams {
      padding-bottom: 140px;
      background-color: var(--grey-900);
      background-image: url('../../images/noise.png');
      border-radius: 16px 16px 0px 0px;
      display: flex;
      justify-content: space-between;

      .staff-augmentation__title,
      .staff-augmentation__remote-blocks-wrap {
        flex: 1;
      }

      .staff-augmentation__title-text {
        color: var(--primary-white);

        &:nth-child(even) {
          margin-left: 21%;
        }

        @media (max-width: 1030px) {
          &:nth-child(even) {
            margin-left: 0;
          }
        }

        @media (max-width: 576px) {
          display: inline;

          &:nth-child(even):before {
            content: ' ';
          }
        }
      }

      @media (max-width: 576px) {
        flex-direction: column;
        padding-bottom: 100px;
      }
    }

    @media (max-width: 576pc) {
      padding: 60px 5vw;
    }
  }

  &__title {
    margin-bottom: 40px;

    &.sa_text-blocks {
      .staff-augmentation__title-text:nth-child(even) {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }

    &.sa_technologies {
      text-align: center;
    }

    &.sa_benefits {
      width: 50%;

      @media (max-width: 1030px) {
        width: 100%;
      }
    }

    &-text {
      @include remove-pm;
      @include h3;

      color: var(--grey-1000);

      &:nth-child(even) {
        margin-left: 24%;
      }

      @media (max-width: 576px) {
        display: inline;

        &:nth-child(even):before {
          content: ' ';
        }
      }
    }
  }

  &__text-blocks {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    &-buttons,
    &-button {
      display: none;
    }

    @media (max-width: 576px) {
      display: flex;
      gap: 8px;
      transition: all 0.2s ease-in-out;

      &-wrap {
        overflow: hidden;
        margin: 0 -5vw;
        padding: 0 5vw 40px;
      }

      &-buttons {
        display: flex;
        gap: 40px;
        justify-content: center;
      }

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background: none;
        border: 1px solid var(--grey-600);
        border-radius: 6px;

        &:first-child {
          transform: rotate(180deg);
        }

        &:disabled {
          border-color: var(--grey-400);

          svg path {
            fill: var(--grey-400);
          }
        }
      }
    }
  }

  &__text-block {
    display: flex;
    align-items: center;
    padding: 40px 20px;
    gap: 40px;
    border: 1px solid var(--grey-500);
    border-radius: 4px;

    &-p {
      @include remove-pm;
      @include subtitle-3;
      color: var(--grey-900);
    }

    @media (max-width: 1279px) {
      flex-direction: column;
      justify-content: center;

      &-p {
        text-align: center;
      }
    }

    @media (max-width: 576px) {
      flex: 0 0 90vw;
      padding: 20px 12px;
    }
  }

  &__remote {
    &-block {
      box-sizing: border-box;
      max-height: 112px;
      padding: 40px;
      overflow: hidden;
      border-width: 1px 0px;
      border-style: solid;
      border-color: var(--white-opacity-40);
      transition: max-height 0.2s ease-in-out;
      cursor: pointer;

      @include subtitle-3;
      color: var(--primary-white);

      &:not(:last-child) {
        border-bottom: none;
      }

      &.open,
      &:hover {
        transition: max-height 0.2s ease-in-out;
        background: var(--white-opacity-4);
      }

      @media (min-width: 1030px) {
        &:hover {
          max-height: 500px;
        }
      }

      @media (max-width: 1030px) {
        max-height: 95px;
        padding: 32px 24px;

        &.open {
          max-height: 500px;

          .staff-augmentation__remote-heading:nth-child(3) {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 49px;

      @media (max-width: 576px) {
        margin-top: 5px;
        margin-bottom: 32px;
      }
    }

    &-heading {
      @include remove-pm;
      @include subtitle-3;

      &:nth-child(3) {
        display: none;
      }

      @media (max-width: 576px) {
        font-size: 1rem;

        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          display: block;
          order: 3;
          transform: rotate(90deg);
          transition: transform 0.2s ease-in-out;

          & path {
            fill: var(--primary-white);
          }
        }
      }
    }

    &-text {
      @include remove-pm;
      font-size: 1rem;
    }
  }

  &__section.sa_devs-available {
    padding: 100px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-white);
    margin: -40px 2.5vw 0;
    box-shadow: 0px 88px 53px rgba(98, 98, 98, 0.05), 0px 39px 39px rgba(98, 98, 98, 0.09),
      0px 10px 22px rgba(98, 98, 98, 0.1), 0px 0px 0px rgba(98, 98, 98, 0.1);
    border-radius: 16px;
    min-height: 678px;

    @media (max-width: 1030px) {
      flex-direction: column;
      gap: 40px;
      padding: 60px 5vw;
      margin: -40px 5vw 0;
    }

    @media (max-width: 576px) {
      padding: 40px 5vw;
      margin: -26px 0 0;
    }
  }

  &__devs {
    &-subtitle {
      color: var(--grey-700);
      margin-top: 0;
      margin-bottom: 100px;

      @media (max-width: 1030px) {
        margin-bottom: 40px;
      }
    }

    &-block {
      flex: 1;

      &:first-child {
        .staff-augmentation__title {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .staff-augmentation__title-text {
          @include subtitle-1;
          @include semi-bold;
        }
      }

      .form__left-section {
        padding: 0;
        width: 100%;
      }

      .form__title.contact__text {
        @include subtitle-1;
        @include semi-bold;
      }
    }

    &-separator {
      min-height: 100%;
      width: 1px;
      background: var(--black-opacity-60);
      margin: 0 5%;

      @media (max-width: 1030px) {
        width: 100%;
        min-height: unset;
        height: 1px;
        margin: 0;
      }
    }

    &-services-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media (max-width: 1500px) {
        margin-bottom: 90px;
      }
    }

    &-service {
      @include button-text-small;
      padding: 16px 28px;
      background-color: var(--blue-100);
      cursor: pointer;
      border-radius: 4px;
      position: relative;
      background-image: url('../../images/noise-lighter.png');

      &:hover,
      &.selected {
        background-color: var(--blue-300);
      }
    }

    &-steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &-track,
      &-progress {
        position: absolute;
      }

      &-progress,
      .staff-augmentation__devs-step,
      .staff-augmentation__devs-step-inner {
        background-image: url('../../images/noise-lighter.png');
      }

      &-track {
        width: 99%;
        border: 2px dashed var(--grey-400);
      }

      &-progress {
        &-wrap {
          z-index: 2;
          position: relative;
          margin: 0 -2px;
        }

        position: relative;
        background-color: var(--blue-500);
        height: 4px;
        border-radius: 0 4px 4px 0;
        max-width: 99%;
        transition: width 0.2s ease-out;
      }
    }

    &-step {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      @include body-2;
      color: var(--grey-700);
      background-color: var(--grey-400);
      z-index: 3;
      transition: all 0.2s ease-out;

      &,
      &-outer,
      &-inner {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 100%;
      }

      &.active,
      &.active .staff-augmentation__devs-step-inner {
        background-color: var(--blue-500);
        color: var(--primary-white);
      }

      &-outer,
      &-inner {
        border-radius: 2px;
      }

      &-outer {
        width: 28px;
        height: 28px;
        background-color: var(--primary-white);
      }

      &-inner {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        transition: all 0.2s ease-out;
      }
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 40px;

      @media (max-width: 576px) {
        justify-content: center;
      }
    }

    &-button {
      @include clear-button;
      @include button-text;

      gap: 20px;
      color: var(--grey-1000);
      font-size: 15px;

      &:first-child {
        .staff-augmentation__devs-button-icon {
          transform: rotate(180deg);
        }
      }

      &:disabled {
        color: var(--grey-400);

        .staff-augmentation__devs-button-icon {
          border-color: var(--grey-400);

          svg path {
            fill: var(--grey-400);
          }
        }
      }

      &-icon {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--grey-1000);
        border-radius: 50%;
      }
    }

    &-multi {
      .sa_multi-services {
        &.staff-augmentation__devs-services-wrap {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 32px;

          @media (max-width: 1830px) {
            gap: 16px;
          }

          @media (max-width: 1366px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 1030px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .staff-augmentation__devs-multi-service-wrap {
          @include button-text-small;
          display: flex;
          flex-direction: column;
          gap: 8px;

          @media (max-width: 1830px) {
            font-size: 0.7rem;
          }
        }

        .staff-augmentation__devs-service {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 8px 10px;
          gap: 8px;
        }

        .staff-augmentation__devs-multi-button {
          @include clear-button;
          justify-content: center;
          padding: 10px;
          border: 2px solid var(--grey-1000);
          border-radius: 6px;

          &:first-child:disabled {
            border-color: var(--grey-400);

            svg path {
              fill: var(--grey-400);
            }
          }
        }
      }

      .sa_multi-levels {
        @include button-text-small;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .staff-augmentation__devs-services-wrap {
          margin-bottom: 40px;
        }
      }

      .sa_devs-multi-needs {
        &-text {
          @include button-text;
          margin-bottom: 20px;
        }

        .input-container {
          margin-bottom: 40px;
        }

        .multiline {
          margin-top: 0;
        }

        .staff-augmentation__devs-services-wrap {
          margin-bottom: 0;
        }
      }
    }

    &-error {
      @include button-text;
      text-align: right;
      color: var(--primary-red);
    }
  }

  &__benefits {
    &-wrap {
      display: flex;
      justify-content: space-between;
      gap: 5vw;

      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    &-list {
      @include subtitle-3;
    }

    &-item {
      display: flex;
      gap: 24px;

      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }

    &-bullet path {
      fill: var(--primary-blue);
    }
  }

  &__technologies {
    &-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-item {
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      padding: 32px 32px 90px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: max-height 0.2s ease-in-out;

      &:hover {
        box-shadow: 16px 82px 33px rgba(145, 145, 145, 0.01), 9px 46px 28px rgba(145, 145, 145, 0.05),
          4px 21px 21px rgba(145, 145, 145, 0.09), 1px 5px 11px rgba(145, 145, 145, 0.1),
          0px 0px 0px rgba(145, 145, 145, 0.1);

        .staff-augmentation__technologies-item-points {
          opacity: 1;
        }
      }

      @media (max-width: 576px) {
        padding: 24px;
        max-height: 76px;

        &.open {
          max-height: 3000px;

          .staff-augmentation__technologies-item-arrow {
            transform: rotate(-90deg);
          }
        }
      }

      &-title,
      &-points {
        display: flex;
        flex-direction: column;

        .staff-augmentation__technologies-item-point {
          display: flex;
          gap: 24px;
          align-items: center;
          line-height: 0;

          @media (max-width: 1280px) {
            line-height: 157%;
          }
        }

        .staff-augmentation__technologies-item-point-bullet {
          path {
            fill: var(--grey-900);
          }

          &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &-title {
        justify-content: space-between;
        gap: 33px;

        @media (max-width: 1030px) {
          display: none;
        }
      }

      &-points {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 16px;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-white);
        z-index: 3;
        padding: 40px;
        transition: opacity 0.2s ease-in-out;

        .staff-augmentation__technologies-item-point:not(:last-child) {
          margin-bottom: 0;
        }

        &-div:first-child {
          @include subtitle-3;
          @include semi-bold;
          text-transform: uppercase;
          font-size: 1rem;

          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        @media (max-width: 1030px) {
          position: initial;
          opacity: 1;
          height: auto;
          padding: 0;
        }
      }

      &-arrow {
        &.sa_icon-arrow {
          transform: rotate(-45deg);
          display: none;

          @media (min-width: 576px) and (max-width: 1030px) {
            display: block;
          }
        }

        &.sa_icon-chevron {
          transform: rotate(90deg);
          transition: transform 0.2s ease-in-out;

          @media (min-width: 576px) {
            display: none;
          }
        }

        &-wrap {
          display: flex;
          justify-content: flex-end;

          .sa_icon-arrow {
            transform: rotate(-45deg);
            display: block;
          }
        }
      }

      &-content {
        @include subtitle-3;
        @include semi-bold;

        display: flex;
        gap: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
      }
    }
  }

  &__talent {
    box-sizing: border-box;
    max-width: 340px;
    min-height: 448px;
    border: 1px solid var(--grey-500);
    border-radius: 4px;
    cursor: pointer;
    padding: 20px;
    position: relative;
    flex: 1;

    &:hover {
      box-shadow: 16px 82px 33px rgba(145, 145, 145, 0.01), 9px 46px 28px rgba(145, 145, 145, 0.05),
        4px 21px 21px rgba(145, 145, 145, 0.09), 1px 5px 11px rgba(145, 145, 145, 0.1),
        0px 0px 0px rgba(145, 145, 145, 0.1);
    }

    @media (max-width: 576px) {
      max-width: 100%;
    }

    @media (max-width: 1030px) {
      &:not(:nth-child(-n + 2)) {
        display: none;
      }
    }

    &-header {
      display: none;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .staff-augmentation__title {
        margin-bottom: 0;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;

        .staff-augmentation__title {
          margin-bottom: 40px;
        }

        .staff-augmentation__devs-button {
          align-self: flex-end;
        }
      }
    }

    &-list {
      display: none;
      justify-content: space-between;
      gap: 48px;

      @media (max-width: 1280px) {
        gap: 24px;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        gap: 24px;
      }
    }

    &-avatar {
      height: 198px;
      width: 100%;
      background-size: cover;
      border-radius: 4px;
      position: relative;
    }

    &-availability {
      position: absolute;
      bottom: 0;
      right: 0;

      padding: 8px 20px;
      background-color: var(--primary-blue);
      border-radius: 4px;

      @include body-2;
      color: var(--primary-white);
    }

    &-name {
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    &-position,
    &-skill {
      @include body-2;
      color: var(--grey-700);
    }

    &-position {
      margin-top: 0;
      margin-bottom: 18px;
    }

    &-skills {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    &-skill {
      padding: 8px 20px;
      border: 1px solid var(--black-opacity-60);
      border-radius: 4px;
    }

    .link-arrow-wrap {
      bottom: 20px;
      right: 20px;
      position: absolute;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.link-arrow {
  &-black,
  &-white,
  & {
    transform: rotate(-45deg);
    width: 24px;
    height: 24px;
  }

  &-white path {
    fill: var(--primary-white);
  }
}
