body {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 18px;

  @media (max-width: 1600px) {
    font-size: 15px;
  }
}

@mixin font-zona {
  font-family: 'Zona Pro';
}

@mixin font-manrope {
  font-family: 'Manrope', sans-serif;
}

@mixin light {
  font-weight: 300;
}
@mixin regular {
  font-weight: 400;
}
@mixin medium {
  font-weight: 500;
}
@mixin semi-bold {
  font-weight: 600;
}
@mixin bold {
  font-weight: 700;
}
@mixin italic {
  font-style: italic;
}
@mixin uppercase {
  text-transform: uppercase;
}

@mixin h1 {
  @include font-zona;
  font-style: normal;
  font-weight: 900;
  font-size: 5.96rem;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
@mixin h2 {
  font-weight: 700;
  font-size: 4.768rem;
  line-height: 112%;
  letter-spacing: -0.02em;

  @media (max-width: 576px) {
    font-size: 2.2rem;
  }
}
@mixin h3 {
  font-weight: 500;
  font-size: 2.441rem;
  line-height: 110%;

  @media (max-width: 576px) {
    font-size: 2rem;
  }
}

@mixin subtitle-1 {
  font-weight: 400;
  font-size: 1.953rem;
  line-height: 115%;
}
@mixin subtitle-2 {
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 115%;
}
@mixin subtitle-3 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
}
@mixin body-2 {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 156%;
  letter-spacing: 0.01em;
}
@mixin button-text {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  line-height: 112%;
}
@mixin button-text-small {
  @include button-text;
  font-size: 0.8rem;
  line-height: 170.5%;
}
