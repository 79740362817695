.input-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.helper-text{
    margin-top: 5px;
    font-size: 14px;
    color: yellow;
}

.red-color{
    color: red !important;
}

.input-field{
    font-family: 'Manrope';
    border: none;
    border-bottom: 2px solid #111517;
    font-weight: 500;
    font-style: normal;
    width: 100%;
    padding: 10px 0;
    background-color: transparent;
    min-height: 30px;
    max-height: 300px;
    resize: vertical;
    overflow-y: auto;
}

.multiline{
    resize: none;
    padding: 0;
    height: 30px;
    margin-top: 20px;
}

.input-field:focus{
    outline: none;
}

.input-field::placeholder{
    font-size: 14px;
    color: #111517;
}

.white{
    border-bottom: 2px solid #FDFDFD;
}

.white::placeholder{
    color: #FDFDFD;
}