.magento {
  &__column-image {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    background-color: var(--blue-850);
    background-image: url('../../../images/noise.png');
    overflow: hidden;
  }

  &__image {
    position: absolute;
    width: 100%;
    //height: 681.6px;
    z-index: 2;
  }

  &__clients {
    z-index: 3;
    margin-top: -642px;
  }

  &__column-desctop {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 350px;
    margin-top: 100px;
  }

  &__column-image-background {
    width: 484px;
    height: 682px;
    z-index: 0;
    margin-left: calc(50% - 484px / 2 - 0px);
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &__row-test {
    flex: none;
    display: flex;
    width: 386px;
    justify-content: flex-start;
    text-align: center;
    flex-direction: row;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  &__text-white {
    justify-self: center;
    max-width: 585px;
    height: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 110%;
    color: white;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__subtext-white {
    height: 22px;
    max-width: 205px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 137%;
    letter-spacing: 0.01em;
    color: white;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 7px;
  }

  &__column-mobile {
    flex-direction: column;
    flex: none;
    display: none;
    width: 100%;
    height: auto;
    margin-bottom: 350px;
    margin-top: 100px;
  }

  &__row {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    height: auto;
  }
}


.magento {
  &__row-clients {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    margin-top: 300px;
    z-index: 2;
  }

  &__row-clients-carusel {
    width: 1400px;
  }

  &__row-ciente {
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 5px;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__row-ciente-width {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__card {
    flex: none;
    display: flex;
    width: 100%;
    height: 390px;
    margin-top: 26px;
    background: #fdfdfd;
    border-radius: 4px 4px 0px 0px;
    z-index: 5;
    padding: 32px 0px 40px;
    flex-direction: column;
    cursor: url('../../../images/Home/pointer.svg') 40 40, auto;
  }

  &__card-height {
    flex: none;
    display: flex;
    height: 416px;
    background: #fdfdfd;
    border-radius: 4px 4px 0px 0px;
    z-index: 5;
    padding: 32px 0px 40px;
    flex-direction: column;
    cursor: url('../../../images/Home/pointer.svg') 40 40, auto;
  }

  &__row-ciente-width-small {
    flex: none;
    display: none;
    width: 100%;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__avatar {
    width: 96px;
    height: 96px;
    border-radius: 200px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__column-card {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 60%;
    align-items: flex-start;
  }

  &__card-text-main {
    width: 100%;
    height: auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    color: #111517;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 20px;
  }

  &__card-subtext {
    width: 100%;
    height: auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.01em;
    color: #626262;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 20px;
  }

  &__card-info-2 {
    width: 100%;
    height: 174px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134%;
    color: #626262;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  &__card-info {
    width: 100%;
    height: 148px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134%;
    color: #626262;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  &__row-secondary {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: flex-end;
    height: auto;
    align-items: flex-end;
    align-self: flex-end;
  }

  &__button {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: var(--primary-white);
    border: 1px solid var(--grey-1000);
    justify-self: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  &__button:hover {
    background-color: var(--grey-300);
  }




  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__column-desctop {
      display: none;
    }

    &__column-image-background {
      width: 100%;
      z-index: 0;
      margin-left: 0;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    &__casses-container {
      width: 100%;
      margin-left: auto;
      height: auto;
      overflow: hidden;
    }

    &__column-mobile {
      display: flex;
      gap: 8px;
    }

    &__row-clients-carusel {
      width: 792px;
    }

    &__card {
      height: 344px;
      padding: 5% 0 15px 0;
    }

    &__card-height {
      height: 368px;
      padding: 5% 0 15px 0;
    }

    &__avatar {
      width: 60px;
      height: 60px;
    }

    &__column-card {
      width: 80%;
    }

    &__card-text-main {
      font-size: 20px;
      margin-left: 5px;
      width: 90%;
      height: auto;
    }

    &__card-subtext {
      width: 90%;
      height: auto;
      font-size: 12px;
      margin-left: 5px;
    }

    &__row-ciente {
      width: 92%;
      margin-left: 4%;
      margin-right: 4%;
    }

    &__row-ciente-width {
      display: none;
    }

    &__row-ciente-width-small {
      display: flex;
    }

    &__card-info {
      font-size: 15px;
      -webkit-line-clamp: 9;
      height: 182px;
    }

    &__card-info-2 {
    }
  }

  @media screen and (min-width: 1px) and (max-width: 768px) {

    &__column-desctop {
      display: none;
    }

    &__column-image {
      display: flex;
      margin-top: 0px;
    }


    &__column-image-background {
      width: 100%;
      z-index: 0;
      margin-left: 0;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }


    &__casses-container {
      //width: 100%;
      margin-left: auto;
      height: auto;
      overflow: hidden;
    }

    &__column-mobile {
      display: flex;
      gap: 8px;
    }

    &__row-clients-carusel {
      width: 792px;
    }

    &__card {
      height: 344px;
      padding: 5% 0 15px 0;
    }

    &__card-height {
      height: 368px;
      padding: 5% 0 15px 0;
    }

    &__avatar {
      width: 60px;
      height: 60px;
    }

    &__column-card {
      width: 80%;
    }

    &__card-text-main {
      font-size: 20px;
      margin-left: 5px;
      width: 90%;
      height: auto;
    }

    &__card-subtext {
      width: 90%;
      height: auto;
      font-size: 12px;
      margin-left: 5px;
    }

    &__row-ciente {
      width: 92%;
      margin-left: 4%;
      margin-right: 4%;
    }

    &__row-ciente-width {
      display: none;
    }

    &__row-ciente-width-small {
      display: flex;
    }

    &__card-info {
      font-size: 15px;
      -webkit-line-clamp: 9;
      height: 182px;
    }

    &__card-info-2 {
      font-size: 15px;
      height: 200px;
      -webkit-line-clamp: 10;
    }

    &__defaulte {
      gap: 14px;
      height: 90%;
    }

    &__defaulte-height {
      gap: 14px;
      height: 90%;
      margin-bottom: 0px;
    }

    &__row-secondary {
      display: none;
    }

    &__text-white {
      width: 100%;
      height: auto;
      font-weight: 500;
      font-size: 30px;
    }

    &__subtext-white {
      height: auto;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
    }
  }

}

