.menu {
  &__burgerMenu {
      position: absolute;
      width: 100%;
      min-height: calc(100vh - 100px);
      padding-top: 100px;
      bottom: 100%;
      left: 0;
      display: flex;
      flex-direction: column;
      transform: translateY(0);
      transition: all 1.4s;
      background: var(--grey-50);
      opacity: 0;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

  &__imgCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 3%;
      border: 2px solid var(--grey-1000);
    }

  &__imgCircle:last-child {
      margin-right: 0;
    }

    @media screen and (min-width: 1px) and (max-width: 767px) {
      &__burgerMenu {
        min-height: calc(100vh - 68px);
        padding-top: 0;
      }

      &__navItems {
        padding-top: 78px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1400px) {
      &__burgerMenu {
        min-height: calc(100vh - 98px);
        padding-top: 0;
      }

      &__navItems {
        padding-top: 98px;
      }
    }

    &__burgerMenu.active {
      transform: translateY(100%);
      opacity: 1;
    }

    &__navItems {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 85%;
      margin: 0 auto;
      flex-grow: 1;
    }

    &__navItemWrap {
      padding: 28px 0;
      border-bottom: 1px solid #f4f4f6;
    }

    &__navItemWrap:first-child {
      padding-top: 0;
    }

    &__navItem {
      text-decoration: none;
    }

    &__navLinkWrap {
      width: 100%;
    }

    &__activeLink div {
      background-color: #E0546F !important;
    }

    &__activeLink span {
      color: var(--primary-white) !important;
    }

    &__navItem span {
      padding-left: 3%;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 156%;
      color: var(--grey-1000);
    }

    &__navLink {
      display: block;
      width: 100%;
      padding: 10px 0;
    }

    &__imgContainer {
      position: relative;
      width: 85%;
      margin: 10% auto 0;
    }

    &__imgLinks {
      display: flex;
      flex-direction: row;
      margin-bottom: 3%;
    }

    @media screen and (max-width: 500px) {
      &__imgLinks {
        justify-content: space-between;
      }
    }

    @media screen and (min-width: 1px) and (max-width: 767px) {
      &__imgCircle {
        margin-right: 0;
      }

      &__navItemWrap {
        padding: 3% 0;
      }

      &__imgContainer {
        margin-bottom: 20px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &__imgContainer {
        margin-bottom: 70px;
      }
    }

    @media screen and (orientation: landscape) {
      &__imgContainer {
        margin-top: 0;
      }
    }

    @media screen and (min-height: 1024px) and (max-height: 1400px) {
      &__navItems {
        padding-top: 130px;
      }
    }
}
