.error {
  &__wrapper {
    width: 100%;
    height: 100vh;
    min-height: 670px;
    overflow: hidden;
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__column-not-found {
    height: auto;
    width: 100%;
    align-items: center;
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__logo {
    width: 12%;
  }

  &__column-text{
    width: 500px;
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: auto;
    align-items: center;
  }

  &__text-not-found {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #111517;
  }

  &__subtext-not-found {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #626262;
  }

  &__button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 28px;
    gap: 16px;
    width: auto;
    height: 64px;
    background: #FDFDFD;
    border: 1px solid #222222;
    border-radius: 6px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 500ms;
  }

  &__button:hover {
    background: #DCDCDC;
  }

  &__button-text-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  &__button-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170.5%;
    display: flex;
    align-items: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #111517;
  }

  @media screen and (max-width: 1720px) {
    &__text-not-found {
      font-size: 23px;
    }
    
    &__subtext-not-found {
      font-size: 15px;
    }
    
    &__button {
      padding: 16px 24px;
      height: 56px;
    }
    
    &__button-text-row {
      gap: 16px;
    }
    
    &__button-text {
      font-size: 12px;
    }

    &__logo {
      width: 177px;
    }
  }

  @media screen and (max-width: 680px) {
    &__column-text {
      width: 312px;
    }

    &__button {
      width: 312px;
      justify-content: center;
    }
  }
}