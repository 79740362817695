.magento {
  &-manage__section {
    padding: 80px;
  }

  &-manage__main {
    display: flex;
    background: var(--grey-200);
    padding: 40px 140px 80px;
    gap: 140px;
  }

  &-manage__title {
    display: flex;
    justify-content: center;
    font-size: 44px;
    margin: 0;
    border: 1px solid black;
    border-bottom: none;
    margin-bottom: -40px;
    padding: 54px 0 90px;
    position: relative;
  }

  &-manage__left,
  &-manage__right {
    display: flex !important;
    width: auto !important;
    gap: 24px;
    flex-direction: column;
  }

  &-manage__left {
    margin-right: 70px;
  }

  &-manage__right {
    margin-left: 70px;
  }

  &-manage__block-left,
  &-manage__block-right {
    display: flex;
    justify-content: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 450px;
  }

  &-manage__block-dev {
    padding-right: 52px;
  }

  &-manage__img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    width: 30px;
  }

  &-manage__block-text {
    width: 50%;
  }

  &-manage__block-text2 {
    width: 50%;
  }


  &-manage__text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    font-size: 14px;
    padding: 5px;

    svg {
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-manage__dev {
    font-size: 23px;
    padding-top: 10px;
  }

  &-manage__dev-text {
    font-size: 18px;
    padding-top: 20px;
  }

  &_casses-container {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 10%;
    height: auto;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: #fdfdfd;
    padding-top: 20px;
    padding-bottom: 10px;
  }

    @media screen and (min-width: 362px) and (max-width: 768px) {
    &-manage__section {
      padding: 0px;
    }

    &-manage__team {
      display: flex;
      flex-direction: column;
      background: var(--grey-200);
      padding: 10px 5px;
    }

    &-manage__main {
      display: flex;
      flex-direction: column;
      padding: 50px 20px 20px 20px;
    }

    &-manage__block-left,
    &-manage__block-right {
      display: flex;
      flex-direction: column;
      height: auto;
      border: none;
    }

    &-manage__left,
    &-manage__right {
      display: flex !important;
      width: auto !important;
      gap: 24px;
      flex-direction: column;
      padding: 20px;
    }

    &-manage-flex {
      display: flex;
      padding: 10px;
      justify-content: center;
      flex-direction: column;
    }

    &-manage__arrows {
      display: flex;
      justify-content: flex-end;
      background: var(--grey-200);
      padding: 20px 15px 1px 1px;
    }

    &-manage__left {
      margin-right: 5px;
    }

    &-manage__right {
      margin-left: 5px;
    }

    &-manage__prev {
      margin-right: 0px;
    }

    &-manage__next {
      margin-left: 20px;
    }

    &-manage__img {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
      width: 30px;
    }

    &-manage-block2,
    &-manage-block1 {
      display: flex;
      flex-direction: column;
    }

    &-manage__block-dev {
      padding: 0px;
    }

    &-solutions__remote {
      font-size: 19px;
    }

    &-manage__dev {
      font-size: 19px;
      padding: 15px 0 0;
    }

    &-manage__dev-text {
      font-size: 15px;
      padding: 30px 0 15px
    }

    &-manage__title {
      font-size: 37px;
      padding-bottom: 40px;
    }

    &-manage__text {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      font-size: 11px;
      padding: 5px;
    }
  }

  @media (min-width: 363px) {
    &-manage__arrows {
      display: none;
    }
  }
  @media (min-width: 1280px) {
    &-manage__section {
      padding: 50px;
    }
    &-manage__main {
      display: block;
      background: var(--grey-200);
      padding: 30px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &-manage__section {
     padding-bottom: 30px;
      padding: 0px;
    }
    &-manage__main {
      display: block;
      background: var(--grey-200);
    }
  }
}
