.nav-bar {
  &__header {
    position: fixed;
    z-index: 11;
    height: 90px;
    width: 100%;
    border-color: var(--grey-600);
    border-bottom: 1px solid var(--grey-600);
  }

  &__header-second {
    position: fixed;
    z-index: 11;
    height: 90px;
    width: 100%;
    border-color: var(--primary-white);
    border-bottom: 1px solid var(--white-opacity-40);
  }

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-white);
    z-index: 2;
  }

  &-grey {
    background-image: url('../../images/Home/Grain2.svg');
    background-color: var(--grey-200);
  }

  &-dark {
    background-image: url('../../images/noise.png');
    background-color: var(--grey-1000);
  }

  &__logoWrap {
    margin-left: 3%;
  }

  &__logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 236px;
    height: 60px;
  }

  &__logo {
    width: 60px;
    height: 60px;
    margin: auto;
  }

  &__large {
    width: 172px;
    height: 44px;
    margin: auto;
  }

  &__navItems {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin-right: 40px;
  }

  &__navItem {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    padding: 0px 28px;
  }

  &__navItem-1 {
    text-decoration: none;
    display: none;
    align-items: center;
    height: 90px;
    white-space: nowrap;
    padding: auto;
    background: #2E2E2E;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
  }

  &__navItem-2 {
    text-decoration: none;
    display: none;
    align-items: center;
    height: 90px;
    white-space: nowrap;
    padding: auto;
    background: #2E2E2E;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
  }

  &__test {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    flex-direction: column;
    min-width: 156px;
    max-width: 182px;
    padding: 0 28px 0 28px;
    cursor: pointer;

    .nav-bar__navItem {
      height: 90px;
      display: none;
      background-color: #2E2E2E;
      border: 1px solid var(--white-opacity-40);
      width: 100%;

      .nav-bar__navLink-white {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }

      .nav-bar__navLink {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }
    }

    .nav-bar__navItem:last-child {
      height: 90px;
      display: none;
      background-color: #2E2E2E;
      border: 1px solid var(--white-opacity-40);
      border-top: none;
      width: 100%;

      .nav-bar__navLink-white {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }

      .nav-bar__navLink {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }
    }

    .nav-bar__navItem:first-child {
      height: 90px;
      display: flex;
      background-color: transparent;
      border: none;
      width: 98%;
      padding: 0 24px 0 24px;
    }
  }

  &__test-2 {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 274px;
    white-space: nowrap;
    flex-direction: column;
    min-width: 156px;
    max-width: 182px;
    padding: 0 28px 0 28px;
    cursor: pointer;

    .nav-bar__navItem {
      height: 90px;
      display: flex;
      background-color: #2E2E2E;
      border: 1px solid var(--white-opacity-40);
      width: 98%;
      padding: 0 24px 0 24px;

      .nav-bar__navLink-white {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }

      .nav-bar__navLink {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }
    }

    .nav-bar__navItem:last-child {
      height: 90px;
      display: flex;
      background-color: #2E2E2E;
      border: 1px solid var(--white-opacity-40);
      border-top: none;
      width: 98%;

      .nav-bar__navLink-white {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }

      .nav-bar__navLink {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: normal;
      }
    }

    .nav-bar__navItem:first-child {
      height: 90px;
      display: flex;
      background-color: transparent;
      border: none;
      width: 98%;
      justify-self: flex-start;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1279px) {
    &__test-2 {
      height: 276px;
    }
  }

  &__button {
    text-decoration: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0px 24px;

    justify-content: center;
    margin-top: 15px;
    gap: 232px;
    height: 56px;
    background: var(--grey-1000);
    border-radius: 6px;
    flex: none;
    flex-grow: 0;
    margin-left: 10px;
  }

  &__button-white {
    text-decoration: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0px 24px;

    justify-content: center;
    margin-top: 15px;
    gap: 232px;
    height: 56px;
    background: var(--primary-white);
    border-radius: 6px;
    flex: none;
    flex-grow: 0;
    margin-left: 10px;
  }

  &__navLink,
  &__navLink-white,
  &__button-text,
  &__button-text-black {
    @include button-text-small;
    text-align: center;
  }

  &__navLink,
  &__button-text-black {
    color: var(--grey-1000);
  }

  &__navLink-white,
  &__button-text {
    color: var(--primary-white);
  }

  &__active {
    background-image: url('../../images/Cases/noise.svg');
    background-size: 250px;
    background-position: 0% 0% !important;
    background-color: #e42a4f !important;
  }

  &__active span {
    color: var(--primary-white) !important;
  }

  &__brgButton {
    padding-right: 4%;
    display: none;
  }

  &__brgButton-white {
    padding-right: 4%;
    display: none;
    color: #fdfdfd;
  }

  &__navLink-button {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    line-height: 116%;
    display: flex;
    align-items: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FDFDFD;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    &__navItems {
      margin-right: 20px;
    }

    &__navItem {
      padding: 0 18px;
    }

    &__test {
      padding: 0 10px 0 18px;
      box-sizing: border-box;
    }

    &__test-2 {
      height: 276px;
      padding: 0 10px 0 18px;
      box-sizing: border-box;
    }
  }

  @media screen and (min-width: 1201px) and (max-width: 1300px) {
    &__navItems {
      margin-right: 30px;
    }

    &__navItem {
      padding: 0 24px;
    }

    &__test {
      padding: 0 24px 0 24px;

      .nav-bar__navItem {
        width: 98%;
      }
    }

    &__test-2 {
      padding: 0 24px 0 24px;

      .nav-bar__navItem {
        width: 98%;
      }
    }
  }

  @media (hover: hover) {
    &__navItem:hover span {
      color: #D34260;
      transition-property: color;
      transition-duration: 400ms;
    }

    &__button:hover {
      background-color: #242424;
      transition-property: background-color;
      transition-duration: 100ms;
    }

    &__button-white:hover {
      background-color: #DCDCDC;
      transition-property: background-color;
      transition-duration: 100ms;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 768px) {
    &__header {
      height: 68px;
    }

    &__header-second {
      height: 68px;
    }

    &__logo {
      height: 44px;
      width: 44px;
    }

    &__logo img {
      height: 44px;
      width: 44px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1400px) {
    &__header {
      height: 90px;
    }

    &__header-second {
      height: 90px;
    }

    &__logo {
      height: 56px;
      width: 56px;
    }

    &__logo img {
      height: 56px;
      width: 56px;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 1024px) {
    &__logos {
      width: 60px;
    }

    &__large {
      display: none;
    }

    &__navItems {
      display: none;
    }

    &__brgButton {
      display: inline-block;
    }

    &__brgButton-white {
      display: inline-block;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1800px) {
    &__navItems {
      width: 60%;
    }
  }
}
