.project{
  &__wrapper {
    @extend %wrapper;
    overflow: hidden;
  }

  &__loader {
    flex: none;
    display: flex;
    height: calc(100vh - 90px);
    align-items: center;
  }

  &__block-first{
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    background-image: url('../../images/noise.png');
    background-color: #2E2E2E;
    flex-direction: column;
  }

  &__block-second{
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    background-image: url('../../images/Home/Grain2.svg');
    background-color: var(--grey-200);
    flex-direction: column;
    overflow: hidden;
  }

  &__block-third{
    flex: none;
    display: flex;
    width: 100%;
    height: 745px;
    min-height: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    overflow: hidden;
    margin-top: -25px;
  }

  &__block-third-grain {
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-image: url('../../images/noise.png');
    background-repeat: repeat;
    opacity: 0.4;
  }

  &__block-fours{
    flex: none;
    display: flex;
    width: 100%;
    height: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    overflow: hidden;
    margin-top: -30px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1720px) {
    &__block-third{
      height: calc(100vh - 90px);
      min-height: 447px;
    }

    &__block-fours{
      height: 666px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__block-third{
      height: 497px;
      min-height: 497px;
    }

    &__block-fours{
      height: 666px;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 769px) {
    &__block-third{
      height: 497px;
      min-height: 497px;
    }

    &__block-fours{
      height: 312px;
    }
  }

  &__block-six{
    flex: none;
    display: flex;
    width: 100%;
    height: calc(100vh - 90px);
    min-height: 712px;
    background-image: url('../../images/noise.png');
    background-color: var(--grey-850);
    overflow: hidden;
    margin-top: -30px;
  }

  &__block-seven {
    display: none;
  }

  &__column-title{
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 90%;
    margin-left: 10%;
    margin-top: 152px;
    height: auto;
    flex-direction: column;
  }

  &__title {
    font-family: 'Zona Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 85px;
    line-height: 99%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--primary-white);
  }

  @media screen and (min-width:1720px) {
    &__title {
      font-size: 107px;
      line-height: 107%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--primary-white);
    }
  }

  &__row-subtitle{
    flex: none;
    display: flex;
    width: 60%;
    height: auto;
    gap: 5px;
    flex-direction: row;
    box-sizing: border-box;
  }

  &__subtitle{
    flex: none;
    display: flex;
    text-transform: uppercase;
    flex-wrap: nowrap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
  }

  &__subtitle-2{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
  }

  &__description {
    flex: none;
    display: flex;
    width: 70%;
    height: auto;
    flex-direction: row;
  }

  @media screen and (min-width:1720px) {
    &__description {
      width: 65%;
    }

    &__subtitle-2{
      font-size: 18px;
    }

    &__subtitle{
      font-size: 18px;
    }
  }

  &__images {
    flex: none;
    display: flex;
    margin-top: 52px;
    margin-left: 10%;
    margin-right: 5%;
    width: 85%;
    height: auto;
  }

  &__image-1{
    width: 80%;
    height: 60%;
    border-radius: 20px;
    z-index: 3;
  }

  &__image-2{
    margin-top: 10%;
    margin-left: -62%;
    width: 80%;
    height: 60%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 2;
    opacity: 0.75;
  }

  &__line {
    border: 0;
    clear: both;
    display: block;
    width: 65px;
    background-color: var(--white-opacity-80);
    height: 1px;
    margin-top: 12px;
    margin-right: 6px;
    margin-left: 6px;
  }

  &__hr-1 {
    border: 0;
    clear: both;
    display: block;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 50px;
    margin-bottom: 20px;
    background-color: var(--black-opacity-60);
    height: 1px;
  }

  &__highlights {
    @include subtitle-1;
    @include semi-bold;
    color: var(--grey-900);
    flex: none;
    margin-top: 80px;
    margin-left: 2.5%;
  }

  &__row-highlights {
    flex: none;
    display: flex;
    grid-auto-flow: row;
    height: auto;
    margin-top: 20px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  &__column-highlights {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: auto;
    min-width: 10%;
    max-width: 17.5%;
    height: auto;
    flex-direction: column;
    gap: 0.5em;
  }

  @media screen and (min-width:1720px) {
    &__row-highlights {
      margin-top: 30px;
    }

    &__column-highlights {
      width: auto;
      min-width: 10%;
      max-width: 15.2%;
    }
  }

  &__column-highlights-technologies {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 30%;
    height: auto;
    flex-direction: column;
    gap: 1em;
  }

  &__highlights-title {
    @include body-2;
    @include semi-bold;
    color: var(--grey-900);
    flex: none;
  }

  &__highlights-content {
    @include body-2;
    color: var(--grey-700);
    flex: none;
  }

  &__highlights-content-2 {
    @include body-2;
    color: var(--grey-700);
    flex: none;
  }

  &__highlights-technologies {
    display: flex;
    width: 100%;
    height: auto;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__technologies-button {
    display: inline-block;
    height: 30px;
    border-radius: 5px;
    border: 1px solid var(--grey-700);
    background: transparent;
    text-align: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__row-client {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    margin-top: 20px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__client {
    width: 48%;
  }

  &__about-title {
    @include subtitle-2;
    @include semi-bold;
    color: var(--grey-900);
    flex: none;
  }

  &__about-subtitle {
    @include body-2;
    color: var(--grey-700);
    flex: none;
    margin-top: 20px;
    margin-bottom: -10px;
  }

  &__image-third {
    width: 100%;
  }

  &__row-project {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__row-project-1 {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__column-project {
    flex: none;
    display: grid;
    gap: 1rem;
    box-sizing: border-box;
    width: 48%;
    height: auto;
    grid-template-columns: repeat(1, 1fr);
  }

  &__main-title {
    @include h3;
    @include medium;
    color: var(--grey-900);
    flex: none;
    width: 48%;
  }

  &__client-text {
    width: 100%;
  }

  &__hr {
    border-bottom: 1px solid var(--black-opacity-60);
    border-top: 0px;
    clear: both;
    display: block;
    background-color: transparent;
    height: 0px;
    width: 100%;
  }

  &__hr:last-child {
    display: none;
  }

  &__buisness {
    @include subtitle-1;
    @include semi-bold;
    color: var(--grey-900);
    flex: none;
    margin-top: 80px;
    margin-left: 2.5%;
  }

  @media screen and (max-width: 1280px) {
    &__buisness {
      margin-top: 30px;
    }
  }

  &__column-buisness {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 32%;
    height: auto;
    flex-direction: column;
    gap: 1em;
  }

  &__buisness-subtitle {
    flex: none;
    display: flex;
    @include body-2;
    color: var(--grey-700);
    flex: none;
    vertical-align: text-top;
  }

  &__image-on-image-block {
    flex: none;
    display: flex;
    width: 95%;
    height: 90%;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
  }

  @media screen and (min-width: 1px) and (max-width: 769px) {
    &__image-on-image-block {
      display: none;
    }
  }

  &__image-on-image{
    width: 278px;
    height: 274px;
  }

  @media screen and (min-width: 1720px) {
    &__image-on-image-block {
      width: 97.5%;
      height: 95%;
    }

    &__image-on-image {
      width: 415px;
      height: 409px;
    }
  }

  &__feedback-title {
    @include h3;
    @include medium;
    color: var(--grey-900);
    flex: none;
    width: 27%;
    margin-top: 40px;
  }

  &__column-feedback {
    flex: none;
    display: grid;
    gap: 2rem;
    box-sizing: border-box;
    width: 65%;
    height: auto;
    margin-top: 40px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 1720px) {
    &__feedback-title {
      width: 21%;
    }

    &__column-feedback {
      width: 70%;
    }
  }

  &__feedback-subtitle-wrapper {
    width: 100%;
  }

  &__feedback-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 29px;
    line-height: 130%;
    color: var(--grey-900);
    flex: none;
  }

  &__feedback-subtitle p {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 1px) and (max-width: 540px) {
    &__feedback-subtitle {
      width: calc(100%);
      height: 338px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
    }

    &__feedback-subtitle-wrapper {
      width: 100%;
      height: 338px;
      overflow: hidden;
    }
  }

  &__feedback-user-row {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  &__row-avatar {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    width: 48%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  &__avatar {
    height: 56px;
    width: 56px;
  }

  &__avatar-column {
    flex: none;
    display: grid;
    box-sizing: border-box;
    width: 83%;
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    align-items: stretch;
  }

  &__position {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 145%;
    color: var(--grey-900);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    flex: none;
    align-items: start;
  }

  &__location {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 157%;
    color: var(--grey-700);
    flex: none;
    align-items: end;
  }

  &__read-more {
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 112%;
    display: flex;
    align-items: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: var(--grey-1000);
    flex: none;
    justify-content: flex-end;
    gap: 10px;
  }

  &__link {
    text-decoration: none;
    color: var(--grey-1000);
    text-align: center;
  }

  &__button {
    appearance: none;
    -webkit-appearance: none;
    width: 36px;
    height: 36px;
    border-radius: 200px;
    border-color: black;
    flex: none;
    display: inline-block;
    order: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
    cursor: pointer;
    -webkit-border-radius: 200px;
  }

  &__features-button {
    width: 56px;
    height: 56px;
    border-radius: 200px;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
    background: transparent;
    border: 1px solid var(--white-opacity-40);
    cursor: pointer;
  }

  &__features-arrow-1 {
    margin-left: -2px;
    margin-top: 3px;
    transform: rotate(180deg);
  }

  &__features-arrow-2 {
    margin-left: 2px;
    margin-top: 3px;
  }
  
  &__arrow-1 {
    margin-left: 1px;
    margin-top: 3px;
    transform: rotate(180deg);
  }

  &__arrow-2 {
    margin-left: -1px;
    margin-top: 3px;
  }

  &__features-button:hover {
    .project__features-arrow-1 {
      transform: rotate(180deg) translateX(3px);
    }

    .project__features-arrow-2 {
      transform: translateX(3px);
    }
  }

  &__row-discover {
    display: flex;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -9px;
  }

  &__discover-title {
    @include h3;
    @include medium;
    color: var(--grey-900);
    flex: none;
    width: auto;
    font-size: 37px;
  }

  @media screen and (min-width: 1720px) {
    &__discover-title {
      font-size: 44px;
    }
  }

  &__all-cases {
    width: auto;
    height: auto;
    @include button-text;
    color: var(--grey-1000);
    flex: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
  }

  &__discover-hr {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: var(--black-opacity-60);
    height: 1px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 20px;
  }

  @media screen and (min-width:1720px) {
    &__discover-hr {
      margin-top: 32px;
    }
  }

  &__card:first-child {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 650px;
    height: auto;
    border-radius: 10px;
    border: 1px dashed var(--grey-1000);
    flex-direction: column;
    margin-left: 2.5%;
  }

  &__card {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 650px;
    height: auto;
    border-radius: 10px;
    border: 1px dashed var(--grey-1000);
    flex-direction: column;
    margin-left: 25px;
    text-decoration-line: none;
  }

  &__cards {
    flex: none;
    display: flex;
    margin-top: 30px;
    height: auto;
    flex-direction: row;
    overflow: auto;
    cursor: url('../../images/Home/pointer.svg') 40 40, auto;
  }

  &__cards::-webkit-scrollbar {
    width: 0em;
    height: 0em;
  }

  &__card-title-row{
    flex: none;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    width: 95%;
    height: auto;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 2.5%;
    justify-content: space-between;
  }

  &__card-buttons-row {
    flex: none;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    width: 60%;
    justify-content: flex-end;
    gap: 8px;
  }

  &__card-title-column {
    flex: none;
    display: flex;
    width: 38%;
    height: auto;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.2em;
    margin-bottom: 25px;
  }

  &__technologies-button-2 {
    display: inline-block;
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--grey-700);
    background: transparent;
    text-align: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__card-title{
    @include subtitle-3;
    @include bold;
    color: var(--grey-900);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    text-decoration-line: none;
    width: auto;
  }

  &__card-subtitle{
    @include body-2;
    color: var(--grey-700);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    pointer-events: none;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__image-main {
    width: 100%;
    border-radius: 10px;
  }

  &__talk {
    margin-top: 250px;
  }

  @media screen and (min-width: 1px) and (max-width: 1280px) {
    &__talk {
      margin-top: 100px;
    }
  }

  @media screen and (min-width: 1720px) {
    &__talk {
      margin-top: 310px;
    }
  }

  &__features-wrapper {
    flex: none;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__features {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 92.5%;
    height: 90%;
    border: 1px solid var(--white-opacity-40);
    flex-direction: column;
  }

  &__features-row {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 15%;
    border-bottom: 1px solid var(--white-opacity-40);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }

  &__features-row-second {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 85%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  &__features-image-block {
    width: 65%;
    height: 90%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-right: 2.5%;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    overflow: hidden;
  }

  &__features-image {
    height: 100%;
  }

  &__features-buttons {
    flex: none;
    display: flex;
    width: 130px;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 2.5%;
  }

  &__div-image {
    height: auto;
    width: 1000px;
    margin-left: -900px;
    margin-top: -30px;
  }

  &__features-title {
    font-style: normal;
    font-weight: 500;
    font-size: 37px;
    line-height: 108%;
    letter-spacing: -0.01em;
    color: var(--primary-white);
    margin-left: 2.5%;
  }

  &__features-dropdowns {
    flex: none;
    display: flex;
    width: 30%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: auto;
    border-right: 1px solid var(--white-opacity-40);
  }

  &__features-dropdowns-row {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 90%;
    margin-left: 5%;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__features-dropdowns-column {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 52px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  &__features-dropdowns-column-2 {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 85px;
    flex-direction: column;
    cursor: pointer;

    .project__features-dropdowns-row {
      .project__features-dropdowns-title {
        color: var(--primary-white);
      }
    }
  }

  &__features-dropdowns-title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 145%;
    width: 85%;
    color: var(--white-opacity-60);
    flex: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__features-dropdowns-text {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 137%;
    letter-spacing: 0.02em;
    color: var(--white-opacity-80);
    flex: none;
    margin-top: 0px;
  }

  &__features-dropdowns-row-text {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 90%;
    margin-left: 5%;
    height: auto;
  }

  &__hr-2 {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: var(--white-opacity-60);
    height: 1px;
  }

  &__hr-2:last-child {
    display: none;
  }

  &__features-image-2 {
    width: 100%;
  }

  @media screen and (min-width: 1720px){
    &__row-subtitle {
      width: 980px;
    }

    &__features-image {
      height: auto;
      width: 100%;
    }

    &__block-six{
      height: calc(100vh - 90px);
      min-height: 970px;
    }

    &__features-dropdowns {
      justify-content: center;
      gap: 0.5em;
    }

    &__features-image-block {
      border-radius: 15px;
    }

    &__features-dropdowns-column{
      height: 60px;
    }

    &__features-dropdowns-column-2{
      height: 108px;
      gap: .5em;
    }

    &__features-dropdowns-title {
      font-size: 23px;
    }

    &__features-dropdowns-text {
      font-size: 14px;
    }

    &__div-image {
      height: 100%;
      width: 1000px;
      margin-left: -900px;
      margin-top: 0px;
   }
  }

  @media screen and (min-width: 768px) and (max-width: 1180px){
    &__block-six {
      height: 600px;
      min-height: unset;
    }

    &__features {
      width: 100%;
      height: 100%;
      border: 0px solid var(--white-opacity-60);
    }

    &__features-image-block {
      display: none;
    }

    &__features-dropdowns {
      width: 100%;
      justify-content: center;
      align-items: center;
      border-right: 0px solid var(--white-opacity-60);
    }

    &__features-dropdowns-column-2{
      gap: 0.5em;
    }

    &__features-title {
      margin-left: 5%;
      font-size: 37px;
    }

    &__features-buttons {
      margin-right: 5%;
      width: 115px;
    }

    &__features-button {
      width: 46px;
      height: 46px;
    }

    &__hr-2 {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    &__block-seven {
      display: flex;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 768px){
    &__block-six {
      height: 600px;
      min-height: unset;
    }

    &__features {
      width: 100%;
      height: 100%;
      border: 0px solid var(--white-opacity-60);
    }

    &__features-image-block {
      display: none;
    }

    &__features-dropdowns {
      width: 100%;
      justify-content: center;
      align-items: center;
      border-right: 0px solid var(--white-opacity-60);
    }

    &__features-row {
      height: 15%;
    }

    &__features-title {
      margin-left: 5%;
      font-size: 24px;
    }

    &__features-buttons {
      display: none;
    }

    &__hr-2 {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    &__block-seven {
      display: flex;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1280px){
    &__row-highlights {
      flex: none;
      display: flex;
      height: auto;
      margin-top: 20px;
      width: 95%;
      margin-left: 2.5%;
      margin-right: 2.5%;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 3em;
    }

    &__column-highlights {
      flex: none;
      display: flex;
      box-sizing: border-box;
      width: auto;
      min-width: 25%;
      max-width: 35%;
      height: auto;
      flex-direction: column;
      gap: 1em;
    }

    &__column-highlights-technologies {
      width: 55%;
      gap: 1em;
    }

    &__row-client {
      flex: none;
      display: flex;
      flex-direction: column;
      height: auto;
      margin-top: 20px;
      width: 95%;
      margin-left: 2.5%;
      margin-right: 2.5%;
      box-sizing: border-box;
      gap: 2em;
      margin-bottom: 60px;
    }

    &__client {
      width: 100%;
    }

    &__row-project {
      flex-direction: column;
      justify-content: none;
      margin-bottom: 60px;
      gap: 2em;
    }

    &__row-project-1 {
      flex-direction: column;
      justify-content: none;
      margin-bottom: 60px;
      gap: 1em;
    }

    &__main-title {
      width: 100%;
    }

    &__column-project {
      width: 100%;
    }

    &__column-buisness {
      width: 100%;
      gap: 1em;
    }

    &__feedback-title {
      width: 100%;
      margin-top: 0px;
    }

    &__column-feedback {
      width: 100%;
      margin-top: 0px;
    }

    &__column-title{
      margin-top: 120px;
      margin-left: 2.5%;
      height: 30px;
    }

    &__subtitle{
      font-size: 15px;
      line-height: 157%;
    }

    &__subtitle-2{
      font-size: 15px;
      line-height: 157%;
    }

    &__row-subtitle{
      width: 680px;
    }

    &__images {
      flex: none;
      display: flex;
      margin-top: 160px;
      margin-left: 2.5%;
      margin-right: 5%;
      width: 97.5%;
      height: auto;
      overflow: hidden;
    }

    &__image-1{
      width: 882.6px;
      height: 494px;
    }

    &__image-2{
      margin-top: 90px;
      margin-left: -680px;
      width: 882.6px;
      height: 494px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      z-index: 2;
      -webkit-filter: brightness(0.8);
      filter: brightness(0.8);
    }

    &__card {
      width: 650px;
    }

    &__card:first-child {
      width: 650px;
    }

    &__image-on-image{
      width: 228px;
      height: 224px;
    }

    &__div-image {
      display: none;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 767px) {
    &__row-highlights {
      flex: none;
      display: grid;
      height: auto;
      margin-top: 20px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      gap: 1.5em;
    }

    &__highlights {
      margin-left: 5%;
    }

    &__column-highlights {
      flex: none;
      display: flex;
      box-sizing: border-box;
      width: auto;
      width: 100%;
      height: auto;
      flex-direction: column;
      gap: 0.5em;
    }

    &__column-highlights-technologies {
      width: 100%;
      gap: 0.5em;
    }

    &__highlights-title {
      font-size: 15px;
      width: 200px;
    }

    &__highlights-content {
      font-size: 15px;
      width: 228px;
    }

    &__highlights-content-2 {
      font-size: 12px;
      width: auto;
    }

    &__technologies-button {
      display: inline-block;
      height: 30px;
      border-radius: 5px;
      border: 1px solid var(--grey-700);
      background: transparent;
      text-align: center;
      justify-content: center;
      padding-left: 14px;
      padding-right: 14px;
    }

    &__highlights-technologies {
      gap: 0.5em;
    }

    &__row-client {
      flex: none;
      display: flex;
      flex-direction: column;
      height: auto;
      margin-top: 20px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      box-sizing: border-box;
      gap: 1em;
      margin-bottom: 60px;
    }

    &__hr-1 {
      width: 90%;
      margin-left: 5%;
    }

    &__client {
      width: 100%;
    }

    &__about-subtitle {
      margin-top: 15px;
    }

    &__row-project {
      flex-direction: column;
      justify-content: none;
      margin-bottom: 90px;
      margin-top: 70px;
      gap: 1em;
      width: 90%;
      margin-left: 5%;
    }

    &__buisness {
      width: 90%;
      margin-left: 5%;
      margin-top: 60px;
    }

    &__row-project-1 {
      flex-direction: column;
      justify-content: none;
      margin-bottom: 90px;
      margin-top: 40px;
      gap: 1em;
      width: 90%;
      margin-left: 5%;
    }

    &__main-title {
      width: 100%;
      margin-bottom: 38px;
    }

    &__column-project {
      width: 100%;
      gap: 2em;
    }

    &__column-buisness {
      width: 100%;
      gap: 1em;
    }

    &__feedback-title {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 25px;
    }

    &__column-feedback {
      width: 100%;
      margin-top: 0px;
    }

    &__feedback-user-row {
      flex-direction: column;
      justify-content: none;
      gap: 1em;
      margin-top: 30px;
    }

    &__row-avatar {
      width: 100%;
      justify-content: flex-start;
      gap: 10px;
    }

    &__read-more {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 112%;
      display: flex;
      align-items: center;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      color: var(--grey-1000);
      flex: none;
      justify-content: flex-start;
    }

    &__row-discover {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1.5em;
      margin-top: 60px;
    }

    &__all-cases {
      justify-content: flex-start;
    }

    &__column-title{
      margin-top: 120px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      height: 30px;
    }

    &__title {
      font-style: normal;
      font-weight: 900;
      font-size: 37px;
      line-height: 99%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--primary-white);
      flex: none;
    }

    &__subtitle{
      font-size: 15px;
      line-height: 157%;
    }

    &__subtitle-2{
      font-size: 15px;
      line-height: 157%;
      margin-top: -8px;
    }

    &__row-subtitle{
      width: 100%;
      flex-direction: column;
      gap: 1em;
      margin-top: 30px;
    }

    &__line {
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    &__description {
      flex: none;
      display: flex;
      width: 100%;
      height: auto;
      flex-direction:unset;
    }

    &__images {
      flex: none;
      display: flex;
      margin-top: 190px;
      margin-left: 5%;
      width: 95%;
      height: auto;
      overflow: unset;
      overflow-x: clip;
      overflow-y: visible;
    }

    &__block-first {
      overflow: unset;
    }

    &__image-1{
      width: 542.5px;
      height: 304px;
    }

    &__image-2{
      margin-top: 60px;
      margin-bottom: -10px;
      margin-left: -420px;
      width: 542.5px;
      height: 304px;
      border-radius: 20px;
      z-index: 2;
      opacity: 1;
      box-shadow: 356px 364px 204px rgba(0, 0, 0, 0.01), 200px 205px 172px rgba(0, 0, 0, 0.05), 89px 91px 127px rgba(0, 0, 0, 0.09), 22px 23px 70px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      -webkit-filter: brightness(0.85);
      -webkit-box-shadow: 356px 364px 204px rgba(0, 0, 0, 0.01),
        200px 205px 172px rgba(0, 0, 0, 0.05),
        89px 91px 127px rgba(0, 0, 0, 0.09),
        22px 23px 70px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
      filter: brightness(0.85);
    }

    &__card {
      width: 322px;
      margin-left: 10px;
    }

    &__card:first-child {
      width: 322px;
    }

    &__card-buttons-row {
      display: none;
    }

    &__card-title-column {
      width: 100%;
      margin-bottom: 20px;
    }

    &__image-on-image{
      width: 228px;
      height: 224px;
    }

    &__div-image {
      display: none;
    }

    &__about-subtitle {
      font-size: 15px;
      line-height: 157%;
      margin-top: 25px;
    }

    &__buisness-subtitle {
      font-size: 15px;
      line-height: 157%;
    }
  }
}
