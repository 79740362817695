body {
  max-width: 100vw; /* mui select adds a padding. see https://stackoverflow.com/questions/60235781/react-material-ui-select-disablescrolllock-make-menu-position-relative-to-an */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=text],
input[type=button] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-pagination-bullet {
  z-index: 1 !important;
  margin-left: 7px !important;
}

.swiper-pagination-bullet:first-child {
  z-index: 1 !important;
  margin-left: 0px !important;
}

/*.swiper-pagination-bullet-active {*/
/*  position: absolute !important;*/
/*  background-color: #D34260 !important;*/
/*  width: 35px !important;*/
/*  height: 9px !important;*/
/*  margin-left: -16px !important;*/
/*  margin-right: 10px !important;*/
/*  margin-top: 11px !important;*/
/*  z-index: 2 !important;*/
/*  border-radius: 5px !important;*/
/*}*/

/*.swiper-pagination-bullet-active:last-child {*/
/*  background-color: #D34260 !important;*/
/*  width: 25px !important;*/
/*  margin-left: -16px !important;*/
/*  margin-right: -0px !important;*/
/*}*/

/*.swiper-pagination-bullet-active:first-child {*/
/*  background-color: #D34260 !important;*/
/*  width: 25px !important;*/
/*  margin-left: -7px !important;*/
/*  margin-right: -0px !important;*/
/*}*/

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .swiper-pagination-bullet-active {
    margin-top: 8px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .swiper-pagination-bullet-active {
    margin-top: 8px !important;
  }
}

.swiper-pagination {
  margin-top: 20px;
}

.my-custom-pagination-div {
  margin-bottom: 0px;
  height: 20px;
  text-align: center;
  border-top-left-radius: 1000px 150%;
  border-top-right-radius: 1000px 150%;
  background-color: #FDFDFD;
  z-index: 3;
  margin-top: -25px;
  border-top: 2px solid #2D3295;
  width: 100%;
  padding: 10px 0 0 0;
}
