.magento {
  &-developer__section {
    display: flex;
    flex-direction: column;
    background: var(--grey-100);
  }

  &-developer__title {
    text-align: center;
    font-size: 44px;
    line-height: 40px;
    margin-top: 70px;
  }


  &-developer__company-wrap {
    margin: auto 56px;
    display: grid;
    list-style-type: none;
    grid-template-columns: repeat(5, 1fr);
    padding: 0;

    :nth-last-child(-n+5) {
      border-bottom: none;
    }
  }

  &-developer__company {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--grey-1000);
    padding: 0 28px;
    height: 200px;

    > svg {
      width: 100%;
    }
  }


  @media (max-width: 1281px) {
    &-developer__section {
      display: flex;
      flex-direction: column;
      background: var(--grey-100);
    }
  }


  @media (max-width: 768px) {
    &-developer__company {
      border-bottom: none;

      > svg {
        width: 80%;
        padding: 0px;
        height: 170px;
        margin: 0;
      }
    }

    &-developer__title {
      font-size: 37px;
      padding: 80px 0px 20px 0px;
      margin: 0;
      height: 100%;
    }
  }

  @media (max-width: 568px) {
    &-developer__title {
      font-size: 30px;
      margin-top: 20px;
      padding: 20px 0px 20px 0px;
    }
  }

}

