.about {
  &__wrapper {
    overflow: hidden;
    background-color: #fdfdfd;
  }

  &__loader {
    flex: none;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: url('../../images/BackgroundLogoRed.svg');
    background-color: #1d1c1c;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    overflow: hidden;
  }

  &__row {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    height: auto;
  }

  &__row-test {
    flex: none;
    display: flex;
    width: 386px;
    justify-content: flex-start;
    text-align: center;
    flex-direction: row;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  &__row-2 {
    flex: none;
    display: flex;
    width: 100%;
    height: 90px;
    flex-direction: row;
    margin-top: 2.5em;
    gap: 0.5em;
    justify-content: center;
  }

  &__row-g2 {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    flex-direction: row;
    justify-content: space-around;
  }

  &__row-g2-2 {
    flex: none;
    display: none;
    width: 90%;
    margin-left: 5%;
    flex-direction: row;
    justify-content: space-around;
  }

  @media screen and (min-width: 1720px) {
    &__row-g2 {
      display: none;
    }

    &__row-g2-2 {
      display: flex;
    }
  }

  &__g2-main {
    width: 23%;
  }

  &__g2-main-1 {
    width: 254px;
  }

  &__casses-tablet-row {
    display: none;
  }

  &__vl {
    border-left: 1px solid #dddfe4;
  }

  &__vl-1 {
    border-left: 1px solid #dddfe4;
  }

  &__footer {
    background-color: var(--grey-900);
    background-image: url('../../images/noise.png');
  }

  &__footer-content {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media screen and (min-width: 1px) and (max-width: 767px) {
    &__footer-content {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &__row-card {
    flex: none;
    display: flex;
    width: 96%;
    height: 96%;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
  }

  &__row-ciente {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__row-ciente-width {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__row-ciente-width-small {
    flex: none;
    display: none;
    width: 100%;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row;
  }

  &__row-secondary {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: flex-end;
    height: auto;
    align-items: flex-end;
    align-self: flex-end;
  }

  &__card-row-secondary {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    text-align: end;
    flex-direction: row;
    margin-top: 76px;
  }

  &__row-clients {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    margin-top: 270px;
    z-index: 2;
  }

  &__row-clients-carusel {
    flex: none;
    display: flex;
    width: 1400px;
    flex-direction: row;
  }

  &__row-main-text {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  }

  &__row-main-text-2 {
    flex: none;
    display: flex;
    width: 630px;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
  }

  &__row-info {
    flex: none;
    display: flex;
    height: 23%;
    width: 100%;
    flex-direction: row;
    z-index: 10;
  }

  @keyframes picture2 {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0);
    }
  }

  &__row-main {
    flex: none;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__to-bottom {
    flex: none;
    display: flex;
    height: 100%;
    width: 10%;
    align-items: center;
    justify-content: center;
    border-top: 1px solid gray;
    border-right: 1px solid gray;
    backdrop-filter: blur(10px);
  }

  &__div-image-1 {
    z-index: 2;
    margin-top: -70px;
    margin-left: 20%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-1;
    animation-timing-function: linear;
  }

  @keyframes bounce-1 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-30px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-2 {
    z-index: 4;
    margin-top: -210px;
    margin-left: 45%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 1.9s;
    animation-iteration-count: infinite;
    animation-name: bounce-2;
    animation-timing-function: linear;
  }

  @keyframes bounce-2 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-20px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-3 {
    z-index: 3;
    margin-top: -100px;
    margin-left: 20%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
    animation-name: bounce-3;
    animation-timing-function: linear;
  }

  @keyframes bounce-3 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-30px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-4 {
    z-index: 5;
    margin-top: -205px;
    margin-left: 45%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 2.1s;
    animation-iteration-count: infinite;
    animation-name: bounce-4;
    animation-timing-function: linear;
  }

  @keyframes bounce-4 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-40px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-5 {
    z-index: 1;
    margin-top: -200px;
    margin-left: -5%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-name: bounce-5;
    animation-timing-function: linear;
  }

  @keyframes bounce-5 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-35px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-6 {
    z-index: 4;
    margin-top: -85px;
    margin-left: 45%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-name: bounce-6;
    animation-timing-function: linear;
  }

  @keyframes bounce-6 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-28px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-7 {
    z-index: 2;
    margin-top: -190px;
    margin-left: 20%;
    width: 100%;
    height: 30%;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
    animation-duration: 2.3s;
    animation-iteration-count: infinite;
    animation-name: bounce-7;
    animation-timing-function: linear;
  }

  &__div-image-8 {
    display: none;
  }

  @keyframes bounce-7 {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-33px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__div-image-8 {
    z-index: 2;
    margin-top: -680px;
    margin-left: 115%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-8;
    animation-timing-function: linear;
  }

  &__main-image-1 {
    width: 448px;
    height: 630px;
    transform: rotate(-0.25turn);
  }

  &__div-image-main {
    width: 60%;
    height: 750px;
    z-index: 1;
    margin-top: -750px;
    margin-left: 40%;
  }

  &__div-image-main-2 {
    width: 100%;
    height: 750px;
    z-index: 8;
    margin-top: -750px;
    opacity: 1;
    mix-blend-mode: soft-light;
    background-image: url('../../images/Home/Grain.svg');
    background-color: transparent;
  }

  &__to-bottom-second {
    flex: none;
    display: flex;
    height: 100%;
    width: 25%;
    align-items: center;
    justify-content: center;
    border-top: 1px solid gray;
    border-right: 1px solid gray;
    backdrop-filter: blur(10px);
  }

  &__to-bottom-third {
    flex: none;
    display: flex;
    height: 100%;
    width: 39.8%;
    align-items: center;
    border-top: 1px solid gray;
    backdrop-filter: blur(10px);
  }

  @media screen and (min-width: 1280px) and (max-width: 1720px) {
    &__to-bottom-second {
      width: 28%;
    }

    &__to-bottom-third {
      width: 33.8%;
    }
  }

  &__to-bottom-main-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #fdfdfd;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    justify-content: flex-start;
  }

  &__to-bottom-secondary-text {
    height: 88px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    justify-content: flex-start;
    margin-top: 10px;
  }

  @media screen and (min-width: 980px) and (max-width: 1280px) {
    &__row-info {
      height: auto;
      min-height: 23%;
      max-height: 28%;
    }

    &__to-bottom-second {
      height: auto;
    }

    &__to-bottom-secondary-text {
      height: auto;
    }
  }

  &__button-to-bottom {
    position: absolute;
    width: 60px;
    height: 60px;
    background-image: url('../../images/noise.png');
    background-color: #434343;
    border-radius: 200px;
    border: 0px;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__button-to-bottom-text {
    margin-top: 3px;
  }

  &__text-main {
    font-family: 'Zona Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 85px;
    line-height: 99%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fdfdfd;
  }

  &__text-main-secondary {
    flex: none;
    display: inline-block;
    margin-left: 20px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    width: 300px;
  }

  &__column {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 350px;
    margin-top: 100px;
  }

  &__column-desctop {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 350px;
    margin-top: 100px;
  }

  &__column-mobile {
    flex-direction: column;
    flex: none;
    display: none;
    width: 100%;
    height: auto;
    margin-bottom: 350px;
    margin-top: 100px;
  }

  &__column-to-bottom-second {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 80%;
    height: 90%;
    margin-top: 10%;
    margin-left: 10%;
  }

  &__column-first {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: 100vh;
    max-height: 840px;
    min-height: 740px;
    background: #4b4b4b;
    background-image: radial-gradient(64.37% 64.37% at 50% 35.63%, #4b4b4b 0%, #111517 100%);
    overflow: hidden;
  }

  &__column-main-first {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 50%;
    height: 100%;
    margin-left: 10%;
    padding-top: 10%;
    z-index: 9;
  }

  &__column-main-second {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 40%;
    height: 750px;
    align-self: center;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
    animation-name: picture1;
  }

  @keyframes picture1 {
    0% {
      transform: translateX(500px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &__row-image-first-mobile {
    display: none;
  }

  &__column-main-second-mobile {
    display: none;
  }

  &__div-image-main-mobile {
    display: none;
    opacity: 0.7;
  }

  &__g2-small {
    width: auto;
    height: 70px;
  }

  @media screen and (min-width: 1720px) and (min-height: 900px) {
    &__column-first {
      flex-direction: column;
      flex: none;
      display: flex;
      width: 100%;
      height: 100vh;
      min-height: 900px;
      max-height: unset;
      background: #4b4b4b;
      background-image: radial-gradient(64.37% 64.37% at 50% 35.63%, #4b4b4b 0%, #111517 100%);
      overflow: hidden;
    }

    &__text-main {
      font-size: 107px;
    }

    &__row-main-text-2 {
      width: 780px;
      align-items: unset;
    }

    &__text-main-secondary {
      font-size: 18px;
      width: 350px;
      line-height: 146%;
      align-self: center;
      margin-top: 0px;
    }

    &__column-main-second {
      margin-top: 50px;
      height: 750px;
      align-self: unset;
    }

    &__div-image-main-2 {
      width: 100%;
      height: 100vh;
      z-index: 8;
      margin-top: -100vh;
      opacity: 1;
      mix-blend-mode: soft-light;
      background-image: url('../../images/Home/Grain.svg');
      background-color: transparent;
    }

    &__div-image-main {
      width: 60%;
      height: 100vh;
      z-index: 1;
      margin-top: -100vh;
      margin-left: 40%;
    }

    &__div-image-1 {
      margin-top: -80px;
      margin-left: 10%;
    }

    &__div-image-2 {
      margin-top: -200px;
      margin-left: 35%;
    }

    &__div-image-3 {
      margin-top: -90px;
      margin-left: 10%;
    }

    &__div-image-4 {
      margin-top: -195px;
      margin-left: 35%;
    }

    &__div-image-5 {
      margin-top: -190px;
      margin-left: -15%;
    }

    &__div-image-6 {
      margin-top: -75px;
      margin-left: 35%;
    }

    &__div-image-7 {
      margin-top: -190px;
      margin-left: 10%;
    }

    &__div-image-8 {
      z-index: 2;
      margin-top: -50px;
      margin-left: 25%;
      width: 100%;
      height: 30%;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      background-color: transparent;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-name: bounce-5;
      animation-timing-function: linear;
    }

    &__to-bottom-main-text {
      font-size: 18px;
    }

    &__to-bottom-secondary-text {
      font-size: 14px;
    }

    &__to-bottom-second {
      width: 20%;
    }

    &__to-bottom-third {
      width: 49.8%;
    }

    &__column-to-bottom-second {
      width: 90%;
      height: 80%;
      margin-left: 5%;
    }
  }

  @media screen and (min-width: 1720px) and (max-height: 900px) {
    &__column-first {
      flex-direction: column;
      flex: none;
      display: flex;
      width: 100%;
      height: 100vh;
      min-height: 900px;
      max-height: unset;
      background: #4b4b4b;
      background-image: radial-gradient(64.37% 64.37% at 50% 35.63%, #4b4b4b 0%, #111517 100%);
      overflow: hidden;
    }

    &__text-main {
      font-size: 107px;
    }

    &__row-main-text-2 {
      width: 780px;
      align-items: unset;
    }

    &__text-main-secondary {
      font-size: 18px;
      width: 350px;
      line-height: 146%;
      align-self: center;
      margin-top: 0px;
    }

    &__column-main-second {
      margin-top: 50px;
      height: 750px;
      align-self: unset;
    }

    &__div-image-main-2 {
      width: 100%;
      height: 900px;
      z-index: 8;
      margin-top: -900px;
      opacity: 1;
      mix-blend-mode: soft-light;
      background-image: url('../../images/Home/Grain.svg');
      background-color: transparent;
    }

    &__div-image-main {
      width: 60%;
      height: 900px;
      z-index: 1;
      margin-top: -900px;
      margin-left: 40%;
    }

    &__div-image-1 {
      margin-top: -80px;
      margin-left: 10%;
    }

    &__div-image-2 {
      margin-top: -200px;
      margin-left: 35%;
    }

    &__div-image-3 {
      margin-top: -90px;
      margin-left: 10%;
    }

    &__div-image-4 {
      margin-top: -195px;
      margin-left: 35%;
    }

    &__div-image-5 {
      margin-top: -190px;
      margin-left: -15%;
    }

    &__div-image-6 {
      margin-top: -75px;
      margin-left: 35%;
    }

    &__div-image-7 {
      margin-top: -190px;
      margin-left: 10%;
    }

    &__div-image-8 {
      z-index: 2;
      margin-top: -50px;
      margin-left: 25%;
      width: 100%;
      height: 30%;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      background-color: transparent;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-name: bounce-5;
      animation-timing-function: linear;
    }

    &__to-bottom-main-text {
      font-size: 18px;
    }

    &__to-bottom-secondary-text {
      font-size: 14px;
    }

    &__to-bottom-second {
      width: 20%;
    }

    &__to-bottom-third {
      width: 49.8%;
    }

    &__column-to-bottom-second {
      width: 90%;
      height: 80%;
      margin-left: 5%;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 568px) {
    &__column-first {
      height: auto;
      background-image: none;
      background-color: #111517;
      max-height: unset;
      min-height: unset;
    }

    &__column-main-second {
      display: none;
    }

    &__row-main {
      width: 100%;
      height: auto;
      margin-left: 0px;
      margin-top: 95px;
      z-index: 10;
      justify-content: center;
      align-items: center;
    }

    &__row-main-text {
      width: auto;
      height: auto;
      justify-content: center;
    }

    &__row-info {
      display: none;
    }

    &__div-image-main {
      display: none;
    }

    &__div-image-main-2 {
      display: none;
    }

    &__column-main-first {
      margin-left: 0%;
      width: 100%;
      height: auto;
      margin-top: 0%;
      gap: 5px;
    }

    &__column-main-second-mobile {
      flex: none;
      display: flex;
      width: 100%;
      height: auto;
      margin-top: 315px;
      flex-direction: column;
      z-index: 10;
      border-top: 1px solid #fdfdfd;
      margin-bottom: auto;
    }

    &__text-main {
      font-weight: 900;
      font-size: 30px;
      line-height: 99%;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #fdfdfd;
    }

    &__text-main-secondary {
      font-weight: 600;
      font-size: 13px;
      line-height: 157%;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.8);
      width: 60%;
      margin-left: 20%;
      height: auto;
      justify-content: center;
    }

    &__row-main-text-2 {
      flex-direction: column;
      gap: 5px;
      width: 100%;
      justify-content: center;
      align-items: unset;
    }

    &__row-main-first-mobile {
      height: auto;
      min-height: 50%;
      border-bottom: 1px solid #fdfdfd;
      width: 100%;
      flex: none;
      display: flex;
      flex-direction: row;
      backdrop-filter: blur(10px);
    }

    &__row-main-second-mobile {
      height: 125px;
      width: auto;
      backdrop-filter: blur(10px);
      flex: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
      overflow-x: scroll;
    }

    &__row-main-second-mobile::-webkit-scrollbar {
      width: 0em;
      height: 0em;
    }

    &__g2-scrol {
      width: 680px;
      flex: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    &__g2-small {
      width: auto;
      height: 45px;
    }

    &__column-to-bottom-second-mobile {
      height: auto;
      width: 50%;
      border-right: 1px solid #fdfdfd;
      flex: none;
      display: flex;
      flex-direction: column;
    }

    &__to-bottom-main-text {
      margin-top: 20px;
      width: 90%;
      margin-left: 5%;
      font-size: 13px;
    }

    &__to-bottom-secondary-text {
      height: auto;
      margin-top: 10px;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 20px;
      font-size: 12px;
    }

    &__div-image-main-mobile {
      display: flex;
      width: 1920px;
      height: 1550px;
      z-index: 9;
      margin-top: -1550px;
      opacity: 1;
      mix-blend-mode: soft-light;
      background-image: url('../../images/Home/Grain.svg');
      background-color: transparent;
    }

    &__row-image-first-mobile {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 1px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &__row-image-second-mobile {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 980px;
    }

    &__div-image-1-mobile {
      width: 12.5%;
      margin-top: -490px;
      margin-left: -150px;
      z-index: 5;
      animation-duration: 1.9s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-2-mobile {
      width: 12.5%;
      margin-top: -590px;
      margin-left: 100px;
      z-index: 4;
      animation-duration: 2.2s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-3-mobile {
      width: 12.5%;
      margin-top: -440px;
      margin-left: -30px;
      z-index: 7;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-4-mobile {
      width: 12.5%;
      margin-top: -660px;
      margin-left: 0px;
      z-index: 3;
      animation-duration: 2.3s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-5-mobile {
      width: 12.5%;
      margin-top: -440px;
      margin-left: -70px;
      z-index: 8;
      animation-duration: 1.9s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-6-mobile {
      width: 12.5%;
      margin-top: -590px;
      margin-left: -100px;
      z-index: 3;
      animation-duration: 1.7s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-7-mobile {
      width: 12.5%;
      margin-top: -440px;
      margin-left: 70px;
      z-index: 6;
      animation-duration: 2.1s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-8-mobile {
      width: 12.5%;
      margin-top: -570px;
      margin-left: -100px;
      z-index: 2;
      animation-duration: 1.8s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__main-image-1-mobile {
      width: 448px;
      height: 630px;
    }

    @keyframes bounce-11 {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-15px);
      }

      100% {
        transform: translateY(0);
      }
    }

    &__div-image-9-mobile {
      width: 100%;
      margin-top: -1070px;
      z-index: 1;
      flex: none;
      display: flex;
      justify-content: center;
    }

    &__div-image-main-mobile-2 {
      width: 1280px;
      height: 1280px;
      margin-left: 150px;
      transform: rotate(0.25turn);
    }

    &__g2-small {
      width: auto;
      height: 45px;
    }
  }

  @media screen and (min-width: 568px) and (max-width: 1030px) {
    &__column-first {
      height: auto;
      background-image: none;
      background-color: #111517;
      max-height: unset;
      min-height: unset;
    }

    &__column-main-second {
      display: none;
    }

    &__row-main {
      width: calc(100% - 40px);
      height: 370px;
      margin-left: 40px;
      margin-top: 55px;
      z-index: 10;
    }

    &__row-info {
      display: none;
    }

    &__div-image-main {
      display: none;
    }

    &__div-image-main-2 {
      display: none;
    }

    &__column-main-first {
      margin-left: 0%;
      width: 100%;
      height: 100%;
      margin-top: 0%;
    }

    &__column-main-second-mobile {
      flex: none;
      display: flex;
      width: 100%;
      height: auto;
      margin-top: 315px;
      flex-direction: column;
      border-top: 1px solid #fdfdfd;
      margin-bottom: auto;
      z-index: 10;
    }

    &__text-main {
      font-size: 60px;
    }

    &__text-main-secondary {
      font-size: 11px;
      width: 250px;
      margin-left: 10px;
    }

    &__row-main-first-mobile {
      height: auto;
      min-height: 50%;
      border-bottom: 1px solid #fdfdfd;
      width: 100%;
      flex: none;
      display: flex;
      flex-direction: row;
      backdrop-filter: blur(10px);
    }

    &__row-main-second-mobile {
      height: 125px;
      backdrop-filter: blur(10px);
      flex: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 65px;
      overflow-x: scroll;
    }

    &__row-main-second-mobile::-webkit-scrollbar {
      width: 0em;
      height: 0em;
    }

    &__g2-scrol {
      width: 980px;
      flex: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    &__g2-small {
      width: auto;
      height: 45px;
    }

    &__column-to-bottom-second-mobile {
      height: auto;
      width: 50%;
      border-right: 1px solid #fdfdfd;
      flex: none;
      display: flex;
      flex-direction: column;
    }

    &__to-bottom-main-text {
      margin-top: 20px;
      width: 90%;
      margin-left: 5%;
    }

    &__to-bottom-secondary-text {
      height: auto;
      margin-top: 10px;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 20px;
    }

    &__div-image-main-mobile {
      display: flex;
      width: 1920px;
      height: 1550px;
      z-index: 9;
      margin-top: -1550px;
      opacity: 1;
      mix-blend-mode: soft-light;
      background-image: url('../../images/Home/Grain.svg');
      background-color: transparent;
    }

    &__row-image-first-mobile {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 1px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &__row-image-second-mobile {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 980px;
    }

    &__div-image-1-mobile {
      width: 12.5%;
      margin-top: -500px;
      margin-left: -150px;
      z-index: 5;
      animation-duration: 1.9s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-2-mobile {
      width: 12.5%;
      margin-top: -600px;
      margin-left: 100px;
      z-index: 4;
      animation-duration: 2.2s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-3-mobile {
      width: 12.5%;
      margin-top: -450px;
      margin-left: -30px;
      z-index: 7;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-4-mobile {
      width: 12.5%;
      margin-top: -670px;
      margin-left: 0px;
      z-index: 1;
      animation-duration: 2.3s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-5-mobile {
      width: 12.5%;
      margin-top: -450px;
      margin-left: -70px;
      z-index: 8;
      animation-duration: 1.9s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-6-mobile {
      width: 12.5%;
      margin-top: -600px;
      margin-left: -100px;
      z-index: 3;
      animation-duration: 1.7s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-7-mobile {
      width: 12.5%;
      margin-top: -450px;
      margin-left: 70px;
      z-index: 6;
      animation-duration: 2.1s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__div-image-8-mobile {
      width: 12.5%;
      margin-top: -580px;
      margin-left: -100px;
      z-index: 2;
      animation-duration: 1.8s;
      animation-iteration-count: infinite;
      animation-name: bounce-11;
      animation-timing-function: linear;
    }

    &__main-image-1-mobile {
      width: 448px;
      height: 630px;
    }

    @keyframes bounce-11 {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-15px);
      }

      100% {
        transform: translateY(0);
      }
    }

    &__div-image-9-mobile {
      width: 100%;
      margin-top: -1090px;
      z-index: 1;
      flex: none;
      display: flex;
      justify-content: center;
    }

    &__div-image-main-mobile-2 {
      width: 1280px;
      height: 1280px;
      margin-left: 150px;
      transform: rotate(0.25turn);
    }
  }

  &__column-card {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 60%;
    align-items: flex-start;
  }

  &__column-image {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    background-color: #2d3295;
    opacity: 1;
    background-image: url('../../images/Home/Grain.svg');
    overflow: hidden;
    margin-top: -80px;
    border-bottom: 1px solid #fdfdfd;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 681.6px;
    z-index: 1;
    overflow: hidden;
  }

  &__column-image-background {
    width: 484px;
    height: 682px;
    z-index: 0;
    margin-left: calc(50% - 484px / 2 - 0px);
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &__casses-tablet-row-third {
    display: none;
  }

  &__clients {
    z-index: 3;
    margin-top: -662px;
  }

  &__colomn-blue {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    margin-top: 75px;
    height: auto;
    background-color: #2d3295;
    opacity: 1;
    background-image: url('../../images/Home/Grain.svg');
  }

  &__block-round {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: -30px;
  }

  &__images-show {
    flex: none;
    display: flex;
    margin-top: -220px;
    height: auto;
    flex-direction: column;
    width: 100%;
  }

  &__images {
    flex: none;
    display: flex;
    height: 500px;
    flex-direction: row;
    width: 100%;
  }

  &__image-main {
    width: 358px;
    height: 358px;
  }

  &__card {
    flex: none;
    display: flex;
    width: 100%;
    height: 390px;
    margin-top: 26px;
    background: #fdfdfd;
    border-radius: 4px 4px 0px 0px;
    z-index: 5;
    padding: 32px 0px 40px;
    flex-direction: column;
    cursor: url('../../images/Home/pointer.svg') 40 40, auto;
  }

  &__card-height {
    flex: none;
    display: flex;
    height: 416px;
    background: #fdfdfd;
    border-radius: 4px 4px 0px 0px;
    z-index: 5;
    padding: 32px 0px 40px;
    flex-direction: column;
    cursor: url('../../images/Home/pointer.svg') 40 40, auto;
  }

  &__defaulte {
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 306px;
  }

  &__defaulte-height {
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 306px;
    margin-bottom: 26px;
  }

  &__card-text-main {
    width: 100%;
    height: auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    color: #111517;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 20px;
  }

  &__card-subtext {
    width: 100%;
    height: auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.01em;
    color: #626262;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 20px;
  }

  &__card-info {
    width: 100%;
    height: 148px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134%;
    color: #626262;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  &__card-info-2 {
    width: 100%;
    height: 174px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134%;
    color: #626262;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  &__card-info p {
    margin: 0;
    padding: 0;
  }

  &__card-info-2 p {
    margin: 0;
    padding: 0;
  }

  &__avatar {
    width: 96px;
    height: 96px;
    border-radius: 200px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__text {
    justify-self: center;
    height: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 110%;
    color: #111517;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 0.25em;
  }

  &__text-white {
    justify-self: center;
    max-width: 585px;
    height: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 110%;
    color: white;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__subtext {
    height: 22px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.01em;
    color: #626262;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  &__subtext-white {
    height: 22px;
    max-width: 205px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 137%;
    letter-spacing: 0.01em;
    color: white;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 7px;
  }

  @media screen and (min-width: 1px) and (max-width: 1720px) {
    &__row-secondary {
      display: none;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 767px) {
    &__column-desctop {
      display: none;
    }

    &__column-mobile {
      display: flex;
      gap: 8px;
    }

    &__row-clients-carusel {
      width: 792px;
    }

    &__card {
      height: 344px;
      padding: 5% 0 15px 0;
    }

    &__card-height {
      height: 368px;
      padding: 5% 0 15px 0;
    }

    &__avatar {
      width: 60px;
      height: 60px;
    }

    &__column-card {
      width: 80%;
    }

    &__card-text-main {
      font-size: 23px;
      margin-left: 5px;
      width: 90%;
      height: auto;
    }

    &__card-subtext {
      width: 90%;
      height: auto;
      font-size: 12px;
      margin-left: 5px;
    }

    &__row-ciente {
      width: 92%;
      margin-left: 4%;
      margin-right: 4%;
    }

    &__row-ciente-width {
      display: none;
    }

    &__row-ciente-width-small {
      display: flex;
    }

    &__card-info {
      font-size: 15px;
      -webkit-line-clamp: 9;
      height: 182px;
    }

    &__card-info-2 {
      font-size: 15px;
      height: 200px;
      -webkit-line-clamp: 10;
    }

    &__defaulte {
      gap: 14px;
      height: 90%;
    }

    &__defaulte-height {
      gap: 14px;
      height: 90%;
      margin-bottom: 0px;
    }

    &__row-secondary {
      display: none;
    }

    &__text-white {
      width: 100%;
      height: auto;
      font-weight: 500;
      font-size: 30px;
    }

    &__subtext-white {
      height: auto;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
    }
  }

  &__card-right {
    margin-top: 31px;
    display: flex;
    height: 358px;
    min-width: 358px;
    max-width: 358px;
    z-index: 3;
    background-color: #2e2e2e;
    transform: rotate(6.55deg);
    justify-content: space-around;
    align-items: center;
  }

  &__card-red {
    margin-top: 31px;
    display: flex;
    height: 358px;
    min-width: 358px;
    max-width: 358px;
    z-index: 3;
    background-color: #c73455;
    transform: rotate(6.55deg);
    justify-content: space-around;
    align-items: center;
  }

  &__card-grey {
    margin-top: 31px;
    display: flex;
    height: 358px;
    min-width: 358px;
    max-width: 358px;
    z-index: 3;
    background-color: #e1e1e1;
    transform: rotate(6.55deg);
    justify-content: space-around;
    align-items: center;
  }

  &__card-white {
    margin-top: 31px;
    display: flex;
    height: 358px;
    min-width: 358px;
    max-width: 358px;
    z-index: 3;
    background-color: #fdfdfd;
    transform: rotate(6.55deg);
    justify-content: space-around;
    align-items: center;
  }

  &__card-left {
    margin-top: 31px;
    display: flex;
    height: 358px;
    width: 358px;
    z-index: 4;
    transform: rotate(-10.79deg);
    justify-content: space-around;
    align-items: center;
  }

  &__card-text {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Zona Pro';
    font-style: italic;
    font-weight: 400;
    font-size: 28px;
    line-height: 115%;
    letter-spacing: 0.01em;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
  }

  &__column-line-none {
    flex-direction: column;
    height: 100%;
    flex: none;
    width: 20%;
    display: flex;
    box-sizing: border-box;
  }

  &__column-with-line {
    flex-direction: column;
    flex: none;
    max-width: 17%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
  }

  &__column-with-line-2 {
    flex-direction: column;
    flex: none;
    max-width: 17%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
  }

  @media screen and (min-width: 1720px) {
    &__column-with-line {
      flex-direction: column;
      flex: none;
      max-width: 18%;
      min-width: 15%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
    }

    &__column-with-line-2 {
      flex-direction: column;
      flex: none;
      max-width: 18%;
      min-width: 14%;
      height: 100%;
      display: flex;
      box-sizing: border-box;
    }
  }

  &__button {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: var(--primary-white);
    border: 1px solid var(--grey-1000);
    justify-self: flex-end;
    cursor: pointer;
  }

  &__button:hover {
    background-color: #efefef;
  }

  &__info-main {
    width: 225px;
    margin-left: 10px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 37px;
    line-height: 110%;
    display: flex;
    align-items: flex-end;
    color: #111517;
    flex: none;
    flex-grow: 1;
    margin-top: -20px;
  }

  @media screen and (min-width: 1720px) {
    &__info-main {
      width: 225px;
      margin-left: 10px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 300;
      font-size: 44px;
      line-height: 110%;
      display: flex;
      align-items: flex-end;
      color: #111517;
      flex: none;
      flex-grow: 1;
      margin-top: -20px;
    }
  }

  &__info-main-img {
    transition: all 0.2s ease-in-out;
  }

  &__info-main:hover {
    .about__info-main-img {
      transform: scale(1.2);
    }
  }

  &__info-secondary {
    width: 150px;
    height: 22px;
    margin-left: 25%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 156%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: #626262;
    flex: none;
    flex-grow: 1;
    letter-spacing: 0.02em;
    margin-top: 10px;
    align-items: flex-start;
  }

  &__column-services {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    margin-top: 75px;
    height: auto;
    background-color: #fdfdfd;
  }

  &__column-talk-container {
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    overflow: hidden;
    gap: 0.2em;
  }

  &__column-talk-container-hidden {
    height: auto;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 100px;
  }

  &__lets-talk {
    @include h2;
    @include medium;
    color: #111517;
  }

  &__text-talk-container,
  &__text-talk-container-transparent {
    width: 90%;
    max-width: 1280px;
    height: auto;
    margin-left: 5%;
    text-align: right;
    margin-top: 10px;
  }

  &__text-talk-container {
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-name: text1;
  }

  &__text-talk-container-secondary {
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-name: text2;
  }

  &__text-talk-container-secondary-transparent,
  &__text-talk-container-secondary {
    width: 90%;
    height: auto;
    margin-left: 7.5%;
  }

  @keyframes text1 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  &__row-talk-container {
    flex: none;
    display: flex;
    width: 70%;
    gap: 1em;
    text-align: center;
    flex-direction: row;
    margin-top: 1em;
    margin-left: 7.5%;
    margin-bottom: 100px;
  }

  @media screen and (min-width: 1720px) {
    &__row-talk-container {
      margin-left: 10%;
    }
  }

  &__talk-container-subtext {
    width: 586px;
    height: auto;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #626262;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: start;
  }

  @keyframes text2 {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media screen and (min-width: 1720px) {
    &__text-talk-container,
    &__text-talk-container-transparent {
      width: auto;
      max-width: 1550px;
      margin-left: 7.5%;
    }

    &__text-talk-container-secondary-transparent,
    &__text-talk-container-secondary {
      max-width: 1720px;
      margin-left: 10%;
    }
  }

  &__button-talk-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 28px;
    gap: 232px;
    width: 205px;
    height: 64px;
    background-image: url('../../images/Cases/noise.svg');
    background-size: 250px;
    background-position: 0% 0% !important;
    background-color: #d11c40 !important;
    transition-property: background-color;
    transition-duration: 500ms;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 10px;
    border: none;
    cursor: pointer;
  }

  &__button-talk-container:hover {
    background-color: #df3053 !important;
  }

  &__button-talk-container-secondary {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 28px;
    gap: 232px;
    width: 213px;
    height: 64px;
    background: #fdfdfd;
    border: 1px solid #222222;
    transition-property: background-color;
    transition-duration: 500ms;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 10px;
    cursor: pointer;
  }

  &__button-talk-container-secondary:hover {
    background: #dcdcdc;
  }

  &__button-talk-container-text {
    width: 149px;
    height: 16px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 116%;
    display: flex;
    align-items: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #fdfdfd;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__button-talk-container-text-secondary {
    width: 170px;
    height: 16px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 116%;
    display: flex;
    align-items: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #111517;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__button-group {
    display: none;
  }

  @media screen and (min-width: 1px) and (max-width: 768px) {
    &__column-image {
      display: flex;
      margin-top: 0px;
    }

    &__images-show {
      height: 100%;
    }

    &__images {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    &__colomn-blue {
      flex-direction: column;
      flex: none;
      display: flex;
      width: 100%;
      height: auto;
      background: #fdfdfd;
      margin-top: 0px;
    }

    &__column {
      margin-bottom: 280px;
    }

    &__image-main {
      width: 320px;
      height: 320px;
    }

    &__card-text {
      color: rgba(255, 255, 255, 0.8);
      font-family: 'Zona Pro';
      font-style: italic;
      font-weight: 400;
      font-size: 23px;
      line-height: 115%;
      letter-spacing: 0.01em;
      margin-left: 50px;
      margin-right: 50px;
    }

    &__card-right {
      height: 320px;
      min-width: 320px;
      z-index: 3;
      background-color: #2e2e2e;
      transform: rotate(0deg);
      justify-content: space-around;
      align-items: center;
    }

    &__card-red {
      height: 320px;
      min-width: 320px;
      z-index: 3;
      background-color: #c73455;
      transform: rotate(0deg);
      justify-content: space-around;
      align-items: center;
    }

    &__card-grey {
      height: 320px;
      min-width: 320px;
      z-index: 3;
      transform: rotate(0deg);
      justify-content: space-around;
      align-items: center;
    }

    &__card-white {
      height: 320px;
      min-width: 320px;
      z-index: 3;
      transform: rotate(0deg);
      justify-content: space-around;
      align-items: center;
    }

    &__card-left {
      height: 320px;
      width: 320px;
      z-index: 4;
      background-color: #111517;
      transform: rotate(0deg);
      justify-content: space-around;
      align-items: center;
    }

    &__text-talk-container,
    &__text-talk-container-transparent {
      width: 80%;
      height: auto;
      margin-left: 10%;
      text-align: left;
      margin-top: 75px;
    }

    &__text-talk-container-secondary,
    &__text-talk-container-secondary-transparent {
      display: none;
    }

    &__row-talk-container {
      flex: none;
      display: flex;
      width: 80%;
      height: auto;
      flex-direction: column;
      margin-top: 1em;
      margin-left: 10%;
      margin-right: 10%;
    }

    &__talk-container-subtext {
      width: 100%;
      height: auto;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #626262;
      flex: none;
      text-align: left;
    }

    &__button-talk-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 28px;
      gap: 232px;
      width: 100%;
      height: 48px;
      border-radius: 6px;
      flex: none;
      align-self: stretch;
      margin-left: 0%;
    }

    &__button-talk-container-secondary {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 28px;
      gap: 232px;
      width: 100%;
      height: 48px;
      background: #fdfdfd;
      border: 1px solid #222222;
      border-radius: 6px;
      flex: none;
      align-self: stretch;
      margin-left: 0%;
    }

    &__button-group {
      display: none;
    }
  }

  @media screen and (min-width: 867px) and (max-width: 1280px) {
    &__row-2 {
      flex: none;
      display: flex;
      width: 95%;
      height: 90px;
      margin-left: 2.5%;
      margin-right: 2.5%;
      flex-wrap: wrap;
      gap: 1.5em;
      margin-top: 3.5em;
    }

    &__column-with-line {
      width: auto;
      min-width: 25%;
      max-width: 35%;
    }

    &__column-with-line-2 {
      width: auto;
      min-width: 25%;
      max-width: 35%;
    }

    &__vl {
      height: 100%;
    }

    &__vl-1 {
      display: none;
    }

    &__column {
      margin-bottom: 350px;
    }

    &__info-main {
      margin-top: -20px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    &__column-image {
      display: flex;
    }

    &__images-show {
      margin-top: -150px;
      height: 400px;
    }

    &__images {
      flex: none;
      display: flex;
      flex-direction: row;
      height: 400px;
    }

    &__colomn-blue {
      flex-direction: column;
      flex: none;
      display: flex;
      width: 100%;
      height: auto;
    }

    &__image-main {
      width: 272px;
      height: 272px;
    }

    &__card-right {
      margin-top: 25px;
      display: flex;
      height: 272px;
      min-width: 272px;
      max-width: 272px;
      z-index: 3;
      background-color: #2e2e2e;
      justify-content: space-around;
      align-items: center;
      transform: rotate(6.55deg);
    }

    &__card-text {
      color: rgba(255, 255, 255, 0.8);
      font-family: 'Zona Pro';
      font-style: italic;
      font-weight: 400;
      font-size: 23px;
      line-height: 115%;
      letter-spacing: 0.01em;
      margin-left: 50px;
      margin-right: 50px;
    }

    &__card-red {
      margin-top: 25px;
      display: flex;
      height: 272px;
      min-width: 272px;
      max-width: 272px;
      z-index: 3;
      background-color: #c73455;
      justify-content: space-around;
      align-items: center;
      transform: rotate(6.55deg);
    }

    &__card-grey {
      margin-top: 25px;
      display: flex;
      height: 272px;
      min-width: 272px;
      max-width: 272px;
      z-index: 3;
      justify-content: space-around;
      align-items: center;
      transform: rotate(6.55deg);
    }

    &__card-white {
      margin-top: 25px;
      display: flex;
      height: 272px;
      min-width: 272px;
      max-width: 272px;
      z-index: 3;
      justify-content: space-around;
      align-items: center;
      transform: rotate(6.55deg);
    }

    &__card-left {
      margin-top: 25px;
      display: flex;
      height: 272px;
      width: 272px;
      z-index: 4;
      justify-content: space-around;
      align-items: center;
      transform: rotate(-10.79deg);
    }

    &__text-talk-container {
      width: 80%;
      height: auto;
      margin-left: 10%;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 71px;
      line-height: 102%;
      text-align: left;
      letter-spacing: -0.02em;
      color: #111517;
      margin-top: 75px;
    }

    &__text-talk-container-secondary {
      display: none;
    }

    &__text-talk-container-transparent {
      width: 80%;
      height: auto;
      margin-left: 10%;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 71px;
      line-height: 102%;
      text-align: left;
      letter-spacing: -0.02em;
      color: #111517;
      margin-top: 75px;
    }

    &__text-talk-container-secondary-transparent {
      display: none;
    }

    &__row-talk-container {
      flex: none;
      display: flex;
      width: 80%;
      flex-direction: column;
      margin-top: 1em;
      margin-left: 10%;
      margin-right: 10%;
    }

    &__talk-container-subtext {
      width: 60%;
      height: auto;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #626262;
      flex: none;
      text-align: left;
    }

    &__button-talk-container {
      display: none;
    }

    &__button-talk-container-secondary {
      display: none;
    }

    &__button-group {
      flex: none;
      display: flex;
      width: 100%;
      text-align: left;
      flex-direction: row;
      margin-top: 20px;
    }

    &__button-talk-container-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 24px 28px;
      gap: 232px;
      width: 205px;
      height: 64px;
      background-image: url('../../images/Home/Grain4.svg');
      background-color: #d34260;
      border-radius: 6px;
      flex: none;
      border: none;
    }

    &__button-talk-container-secondary-group {
      margin-left: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 24px 28px;
      gap: 232px;
      width: 213px;
      height: 64px;
      background: #fdfdfd;
      border: 1px solid #222222;
      border-radius: 6px;
      flex: none;
    }
  }

  &__casses-container {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 10%;
    height: auto;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: #fdfdfd;
    padding-top: 198px;
    padding-bottom: 10px;
  }

  &__casses-row {
    flex: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }

  &__casses-column {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 50%;
    height: 100%;
  }

  &__casses-cards-column {
    flex-direction: column;
    flex: none;
    display: flex;
    margin-top: 25px;
    width: 100%;
  }

  &__casses-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 25px;
    width: 80%;
  }

  &__casses-card-gray {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #efefef;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 25px;
    width: 80%;
  }

  &__casses-card-image {
    position: absolute;
    border: 0.628148px solid #dcdcdc;
  }

  &__casses-card-text {
    width: 474px;
    height: 32px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    color: #222222;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &__casses-card-subtext {
    width: auto;
    margin-top: 4px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 134%;
    color: #626262;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  &__casses-column-text {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 75%;
  }

  &__casses-column-arrow {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 25%;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__casses-title {
    width: 220px;
    height: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 110%;
    color: #111517;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__casses-subtitle {
    width: 451px;
    height: 22px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    letter-spacing: 0.01em;
    color: #626262;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  @media screen and (min-width: 1680px) {
    &__casses-subtitle {
      width: auto;
    }
  }

  &__casses-column-second {
    flex-direction: column;
    flex: none;
    display: flex;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1px) and (max-width: 867px) {
    &__row-2 {
      flex: none;
      display: grid;
      width: 95%;
      height: 440px;
      margin-left: 2.5%;
      margin-right: 2.5%;
      margin-top: 3.5em;
      justify-content: normal;
      margin-bottom: 50px;
    }

    &__vl {
      border-bottom: 1px solid #dddfe4;
      width: 100%;
    }

    &__vl-1 {
      border-bottom: 1px solid #dddfe4;
      width: 100%;
    }

    &__column-with-line {
      width: auto;
      min-width: 1%;
      max-width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
      justify-self: left;
    }

    &__column-with-line-2 {
      width: auto;
      min-width: 1%;
      max-width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
      justify-self: right;
    }

    &__info-secondary {
      width: 79%;
      margin-left: 55px;
      height: auto;
    }
  }

  @media screen and (min-width: 968px) and (max-width: 1680px) {
    &__casses-container {
      width: 96%;
      margin-left: 4%;
      height: auto;
      overflow: hidden;
      padding-top: 100px;
    }
  }

  @media screen and (min-width: 645px) and (max-width: 968px) {
    &__casses-container {
      width: 100%;
      margin-left: 0%;
      height: auto;
      overflow: hidden;
      padding-top: 100px;
    }

    &__casses-column-second {
      display: none;
    }

    &__casses-row {
      height: 100%;
      margin-top: 50px;
      flex-direction: column;
    }

    &__casses-tablet-row {
      flex: none;
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: row;
    }

    &__casses-tablet-column {
      flex: none;
      display: flex;
      width: 50%;
      flex-direction: column;
      text-align: center;
      gap: 0.3em;
    }

    &__casses-tablet-row-title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 37px;
      line-height: 108%;
      letter-spacing: -0.01em;
      color: #111517;
    }

    &__casses-tablet-row-subtitle {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
      color: #626262;
    }

    &__text {
      width: 100%;
      height: auto;
      justify-self: center;
      font-weight: 500;
      font-size: 37px;
      line-height: 108%;
      letter-spacing: -0.01em;
      margin-bottom: 0.25em;
    }

    &__subtext {
      height: auto;
      width: 100%;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
    }

    &__casses-tablet-column-second {
      flex: none;
      display: flex;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      gap: 1em;
      margin-top: 35px;
    }

    &__casses-tablet-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 20px 10px 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid gray;
      width: calc(100% - 40px);
    }

    &__casses-tablet-card-grey {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 20px 10px 20px;
      background: #ffffff;
      border-radius: 4px;
      border: none;
      width: calc(100% - 40px);
      background: #efefef;
    }

    &__cases-mobile-row {
      flex: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: space-between;
    }

    &__casses-column-text-mobile {
      flex: none;
      display: flex;
      flex-direction: column;
      width: 75%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__casses-card-text {
      width: 100%;
      height: auto;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 115%;
      color: #222222;
      flex: none;
      order: 0;
    }

    &__casses-card-subtext {
      width: 100%;
      margin-top: 4px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 134%;
      color: #626262;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    &__casses-tablet-row-third {
      flex: none;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-self: flex-end;
      overflow: hidden;
      margin-top: 100px;
      height: auto;
    }

    &__casses-tablet-image-1 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border: 1px solid gray;
      border-radius: 8px;
      margin-left: -10%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 1;
    }

    &__casses-tablet-image-2 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border-radius: 8px;
      margin-left: -45%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 2;
    }

    &__casses-tablet-image-3 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border-radius: 8px;
      margin-left: -45%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 3;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 645px) {
    &__casses-container {
      width: 100%;
      margin-left: auto;
      height: auto;
      overflow: hidden;
      padding-top: 100px;
    }

    &__casses-column-second {
      display: none;
    }

    &__casses-row {
      height: 100%;
      margin-top: 50px;
      flex-direction: column;
    }

    &__casses-tablet-row {
      flex: none;
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: row;
    }

    &__casses-tablet-column {
      flex: none;
      display: flex;
      width: 90%;
      flex-direction: column;
      text-align: center;
      gap: 0.3em;
    }

    &__casses-tablet-row-title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 37px;
      line-height: 108%;
      letter-spacing: -0.01em;
      color: #111517;
    }

    &__casses-tablet-row-subtitle {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
      color: #626262;
    }

    &__text {
      width: 100%;
      height: auto;
      justify-self: center;
      font-weight: 500;
      font-size: 37px;
      line-height: 108%;
      letter-spacing: -0.01em;
      margin-bottom: 0.25em;
    }

    &__subtext {
      height: auto;
      width: 100%;
      font-size: 12px;
      line-height: 137%;
      letter-spacing: 0.02em;
    }

    &__casses-tablet-column-second {
      &,
      * {
        box-sizing: border-box;
      }

      margin: 35px 5vw 0;
      flex: none;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &__casses-tablet-card,
    &__casses-tablet-card-grey {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 24px;
      border-radius: 4px;
      width: 100%;
    }

    &__casses-tablet-card {
      border: 1px solid gray;
      background-color: var(--primary-white);
    }

    &__casses-tablet-card-grey {
      background: var(--grey-200);

      .about__cases-mobile-row {
        align-items: center;
      }
    }

    &__cases-mobile-row {
      flex: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      justify-content: space-between;
    }

    &__casses-column-text-mobile {
      flex: none;
      display: flex;
      flex-direction: column;
      width: 75%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__casses-card-text {
      width: 100%;
      height: auto;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 115%;
      color: #222222;
      flex: none;
      order: 0;
    }

    &__casses-card-subtext {
      width: 100%;
      margin-top: 4px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 134%;
      color: #626262;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    &__casses-tablet-row-third {
      flex: none;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-self: flex-end;
      overflow: hidden;
      margin-top: 100px;
      height: auto;
    }

    &__casses-tablet-image-1 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border: 1px solid gray;
      border-radius: 8px;
      margin-left: -10%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 1;
    }

    &__casses-tablet-image-2 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border-radius: 8px;
      margin-left: -45%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 2;
    }

    &__casses-tablet-image-3 {
      width: 72%;
      transform: matrix(0.93, -0.37, 0.77, 0.64, 0, 0);
      border-radius: 8px;
      margin-left: -45%;
      margin-top: 7%;
      margin-bottom: 7%;
      z-index: 3;
    }
  }

  &__link {
    text-decoration: none;
    color: var(--grey-900);
  }
}

.home__casses-card-gray {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 30px;
  background: #efefef;
  border-radius: 4px;
  border: none;
  margin-top: 25px;
  width: 40%;
  cursor: pointer;
}

.home__casses-card-gray:hover {
  background: #e1e1e1;
}

.home__casses-card-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid gray;
  margin-top: 25px;
  width: 40%;
  transition: width 0.5s ease-in-out;
  cursor: pointer;
}

.home__casses-card-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid gray;
  margin-top: 25px;
  width: 40%;
  transition: width 0.5s ease-in-out;
  cursor: pointer;
}

.home__casses-card-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 25px 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid gray;
  margin-top: 25px;
  width: 40%;
  transition: width 0.5s ease-in-out;
  cursor: pointer;
}

.casses-block-1 {
  margin-top: -800px;
  margin-left: 50%;
  width: 50%;
  overflow-x: hidden;
  overflow-y: visible;
  height: 700px;
  z-index: 3;
  transition: transform 0.5s;
}

.casses-image-1 {
  transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
  margin-left: 60px;
  border-radius: 18px;
  border: 0px solid gray;
  margin-top: 140px;
  z-index: 3;
  overflow: hidden;
  width: 928px;
  height: 314px;
}

.casses-block-2 {
  margin-top: -480px;
  margin-left: 50%;
  width: 50%;
  overflow-x: hidden;
  overflow-y: visible;
  height: 650px;
  z-index: 2;
  transition: transform 0.5s;
}

.casses-image-2 {
  transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
  margin-left: 60px;
  border-radius: 18px;
  border: 1px solid gray;
  margin-top: 120px;
  z-index: 2;
  overflow: hidden;
  width: 928px;
  height: 314px;
}

.casses-block-3 {
  margin-top: -480px;
  margin-left: 50%;
  width: 50%;
  overflow-x: hidden;
  overflow-y: visible;
  height: 650px;
  z-index: 1;
  transition: transform 0.5s;
}

.casses-image-3 {
  transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
  margin-left: 60px;
  border-radius: 18px;
  border: 1px solid gray;
  margin-top: 120px;
  z-index: 1;
  overflow: hidden;
  width: 928px;
  height: 314px;
}

.home__casses-card-1:hover {
  background: #a82242;
  color: white;
  width: 43%;

  .about__casses-row {
    .about__casses-column-text {
      .about__casses-card-text {
        color: white;
      }
      .about__casses-card-subtext {
        color: white;
      }
    }
  }
}

.home__casses-card-1:hover ~ .casses-block-1 {
  transform: translateY(150px);
}

.home__casses-card-1:hover ~ .casses-block-2 {
  transform: translateX(250px);
}

.home__casses-card-1:hover ~ .casses-block-3 {
  transform: translateX(250px);
}

.home__casses-card-2:hover {
  background: #a82242;
  color: white;
  width: 43%;

  .about__casses-row {
    .about__casses-column-text {
      .about__casses-card-text {
        color: white;
      }
      .about__casses-card-subtext {
        color: white;
      }
    }
  }
}

.home__casses-card-2:hover ~ .casses-block-1 {
  transform: translateX(250px);
}

.home__casses-card-2:hover ~ .casses-block-3 {
  transform: translateX(250px);
}

.home__casses-card-3:hover {
  background: #a82242;
  color: white;
  width: 43%;

  .about__casses-row {
    .about__casses-column-text {
      .about__casses-card-text {
        color: white;
      }
      .about__casses-card-subtext {
        color: white;
      }
    }
  }
}

.home__casses-card-3:hover ~ .casses-block-3 {
  transform: translateY(-200px);
}

.home__casses-card-3:hover ~ .casses-block-1 {
  transform: translateX(270px);
}

.home__casses-card-3:hover ~ .casses-block-2 {
  transform: translateX(270px);
}

@media screen and (min-width: 968px) and (max-width: 1480px) {
  .casses-block-1 {
    margin-left: 52%;
  }

  .casses-block-2 {
    margin-left: 52%;
  }

  .casses-block-3 {
    margin-left: 52%;
  }
}

@media screen and (min-width: 1480px) and (max-width: 1680px) {
  .casses-block-1 {
    margin-left: 52%;
  }

  .casses-block-2 {
    margin-left: 52%;
  }

  .casses-block-3 {
    margin-left: 52%;
  }
}

@media screen and (min-width: 1680px) {
  .casses-block-1 {
    margin-left: 50%;
    margin-top: -850px;
    width: 50%;
    overflow-x: hidden;
    overflow-y: visible;
    height: 750px;
    z-index: 3;
    transition: transform 0.5s;
  }

  .casses-block-2 {
    margin-left: 50%;
  }

  .casses-block-3 {
    margin-left: 50%;
    height: 750px;
  }

  .casses-image-1 {
    margin-left: 90px;
    border-radius: 18px;
    border: 0px solid gray;
    margin-top: 160px;
    z-index: 3;
    overflow: hidden;
    width: 1028px;
    height: 384px;
    transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
  }

  .casses-image-2 {
    transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
    margin-left: 90px;
    border-radius: 15px;
    border: 1px solid gray;
    margin-top: 100px;
    z-index: 2;
    overflow: hidden;
    width: 1028px;
    height: 384px;
  }

  .casses-image-3 {
    transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
    margin-left: 90px;
    border-radius: 15px;
    border: 1px solid gray;
    margin-top: 120px;
    z-index: 1;
    overflow: hidden;
    width: 1028px;
    height: 384px;
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .casses-block-1 {
    margin-top: -10px;
    margin-left: -60%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    height: auto;
    z-index: 3;
    transition: transform 0.5s;
  }
  .casses-image-1 {
    transform: matrix(0.77, -0.31, 1.05, 0.86, 0, 0);
    border-radius: 5px;
    border: 1px solid gray;
    z-index: 3;
    overflow: hidden;
    width: 553px;
    height: 123px;
    margin-left: -200px;
  }
  .casses-block-2 {
    display: none;
  }
  .casses-block-3 {
    display: none;
  }
  .home__casses-card-1:hover {
    background: #a82242;
    color: white;
    width: 83%;

    .about__casses-row {
      .about__casses-column-text {
        .about__casses-card-text {
          color: white;
        }
        .about__casses-card-subtext {
          color: white;
        }
      }
    }
  }
  .home__casses-card-2:hover {
    background: #a82242;
    color: white;
    width: 83%;

    .about__casses-row {
      .about__casses-column-text {
        .about__casses-card-text {
          color: white;
        }
        .about__casses-card-subtext {
          color: white;
        }
      }
    }
  }
  .home__casses-card-3:hover {
    background: #a82242;
    color: white;
    width: 83%;

    .about__casses-row {
      .about__casses-column-text {
        .about__casses-card-text {
          color: white;
        }
        .about__casses-card-subtext {
          color: white;
        }
      }
    }
  }
  .home__casses-card-gray {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #efefef;
    border-radius: 4px;
    border: none;
    margin-top: 25px;
    width: 80%;
  }

  .home__casses-card-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 25px;
    width: 80%;
    transition: width 0.5s ease-in-out;
    margin-right: 10%;
  }

  .home__casses-card-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 25px;
    width: 80%;
    transition: width 0.5s ease-in-out;
    margin-right: 10%;
  }

  .home__casses-card-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 25px;
    width: 80%;
    transition: width 0.5s ease-in-out;
    margin-right: 10%;
  }
}

.about__cards {
  margin-top: 50px;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  display: grid;
  gap: 1rem;
  margin-bottom: 50px;
  grid-template-columns: repeat(3, 1fr);
}

.about__card-services {
  flex: none;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 327px;
  border: 1px solid #9f9f9f;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;
}

.about__card-services-column {
  flex: none;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.about__card-services-column-second {
  flex: none;
  display: flex;
  box-sizing: border-box;
  width: 52%;
  margin-left: 400px;
  height: 90%;
  margin-top: 2.5%;
  flex-direction: column;
  transition: transform 0.5s;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
}

.about__card-services-row {
  flex: none;
  display: flex;
  width: 95%;
  height: auto;
  margin-right: 5%;
  flex-direction: row;
  align-items: center;
}

.about__card-services-row-text {
  width: auto;
  height: auto;
  margin-left: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 137%;
  letter-spacing: 0.02em;
  color: #222222;
}

@media screen and (min-width: 1920px) {
  .about__card-services-row-text {
    font-size: 14px;
  }
}

.about__card-services-row-text-img {
  width: 27px;
}

.about__card-row-image {
  flex: none;
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 60%;
  margin-top: 5%;
  flex-direction: row;
  justify-content: space-between;
}

.about__card-row-text {
  flex: none;
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 25%;
  margin-bottom: 5%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.about__card-services-text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 145%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #222222;
}

.about__card-services-image {
  visibility: visible;
  opacity: 1;
  height: 98%;
  align-self: flex-end;
  display: flex;
}

.about__card-services-image-full {
  visibility: collapse;
  opacity: 0;
  transition: visibility 0s, opacity 0.8s linear;
  height: 98%;
  width: auto;
  align-self: flex-end;
}

.about__arrow {
  display: flex;
}

.about__test {
  display: none;
}

.about__tablet-row {
  display: none;
}

.about__tablet-column {
  display: none;
}

@media screen and (min-width: 1280px) {
  .about__card-services:hover {
    border: 1px solid #e1e1e1;
    box-shadow: 16px 82px 33px rgba(145, 145, 145, 0.01), 9px 46px 28px rgba(145, 145, 145, 0.05),
      4px 21px 21px rgba(145, 145, 145, 0.09), 1px 5px 11px rgba(145, 145, 145, 0.1),
      0px 0px 0px rgba(145, 145, 145, 0.1);

    .about__card-services-image {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0s linear;
      display: none;
    }

    .about__card-services-image-full {
      display: flex;
      visibility: visible;
      opacity: 1;
    }

    .about__arrow {
      display: none;
    }

    .about__card-row-text {
      display: none;
    }

    .about__card-row-image {
      width: 40%;
      margin-right: 0%;
    }

    .about__card-services-column {
      flex-direction: row;
    }

    .about__card-services-column-second {
      display: flex;
      transform: translateX(-390px);
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .about__card-row-image {
    flex: none;
    display: flex;
    width: 87.5%;
    margin-left: 7.5%;
    margin-right: 5%;
    height: 45%;
    margin-top: 5%;
    flex-direction: row;
    justify-content: space-between;
  }

  .about__card-row-text {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 40%;
    margin-bottom: 5%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .about__card-services-image {
    height: 90%;
  }

  .about__card-services-image-full {
    height: 90%;
  }

  .about__card-services-column-second {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 45%;
    margin-left: 400px;
    height: 77.5%;
    margin-top: 10%;
    flex-direction: column;
    transition: transform 0.5s;
    gap: unset;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .about__card-services-column {
    display: none;
  }

  .about__cards {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    gap: 1.5em;
  }

  .about__card-services {
    height: 340px;
    flex-direction: column;
  }

  .about__tablet-row {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 25px;
    margin-top: 17px;
    flex-direction: row;
    justify-content: space-between;
  }

  .about__tablet-column {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 264px;
    margin-top: 17px;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5em;
  }

  .about__card-tablet-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 145%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .about__card-tablet-row-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    letter-spacing: 0.02em;
    color: #222222;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .about__cards {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    gap: 1.5em;
  }

  .about__card-services {
    display: none;
  }

  .about__test {
    flex: none;
    display: flex;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: auto;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: -100px;
  }

  .about__select {
    flex: none;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 4px;
    border: 1px solid #9f9f9f;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .about__select-2 {
    flex: none;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 4px;
    border: 1px solid #9f9f9f;
    flex-direction: column;
    align-items: center;
  }

  .about__select-row-title {
    flex: none;
    display: flex;
    width: 92.5%;
    height: 70px;
    margin-left: 5%;
    margin-right: 2.5%;
    flex-direction: row;
    align-items: center;
  }

  .about__select-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 145%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .about__select-column {
    flex: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0em;
    height: auto;
  }

  .about__select-row-main {
    flex: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
  }

  .about__select-row {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
  }

  .about__select-subtitle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 137%;
    letter-spacing: 0.02em;
    color: #222222;
  }
}
