.magento {
  &-what-we-do__section {
    display: flex;
    justify-content: center;
    padding: 100px 0px 100px 0px;
  }


  &-what-we-do__title {
    margin: 0;
    font-size: 44px;
    width: 40%;
  }

  &__block {
    padding-top: 20px;
  }

  &__block2 {
    padding-top: 20px;
  }

  &-image {
    height: auto;
  }


  .ul {
    display: flex;
    align-items: center;
    font-size: 17px;
  }

  &-text {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    padding: 10px;
    width: 100%;
  }


  @media screen and (min-width: 1281px) {
    &-what-we-do__section {
      padding: 100px 0;
    }

    &-what-we-do__title {
      font-size: 48px;
      width: 50%;
    }

    &__block,
    &__block2 {
      padding-top: 30px;
    }

    &-image {
      padding: 20px 0 0 45px;
    }

    .ul {
      font-size: 18px;
    }

    &-text {
      font-size: 18px;
      padding: 15px;
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1280px) {
    &-what-we-do__section {
      padding: 80px 0;
    }

    &-what-we-do__title {
      font-size: 40px;
      width: 80%;
    }

    &-image {
      display: none;
    }

    &__block,
    &__block2 {
      padding-top: 25px;
    }


    .ul {
      font-size: 17px;
    }

    &-text {
      font-size: 16px;
      padding: 12px;
    }
  }


  @media screen and (max-width: 768px) {
    &-what-we-do__section {
      padding: 60px 0;
    }

    &-what-we-do__arrows {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
    &-what-we-do__prev {
      margin-right: 0px;
    }
    &-what-we-do__next {
      margin-left: 20px;
    }

    &-what-we-do__title {
      font-size: 36px;
      width: 80%;
    }

    &__block,
    &__block2 {
      padding-top: 20px;
    }

    &-image {
      display: none;
    }

    .ul {
      font-size: 16px;
    }

    &-text {
      font-size: 16px;
      padding: 10px;
    }
  }


  @media screen and (max-width: 1280px) {
    &-what-we-do__arrows {
      display: block;
    }
  }

  @media screen and (min-width: 362px) {
    &-what-we-do__arrows {
      display: none;
    }
  }

  @media (max-width: 362px) {
    &-image {
      display: none;
    }
    &-what-we-do__section {
      padding: 50px 0px 50px 0px;
    }

    &-what-we-do__arrows {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      padding-right: 10px;
      padding-top: 25px;
    }

    &__block {
      align-items: center;
    }

    &-what-we-do__title {
      font-size: 30px;
    }

    &-what-we-do {
      padding: 10px;
    }

    &-manage__right-container {
      padding: 2px;
    }
  }
}