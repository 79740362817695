@import url('/src/styles/fonts.css');
@import url('/src/styles/palette.css');

@mixin remove-pm {
  padding: 0;
  margin: 0;
}

%wrapper {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 1000px;
  flex-direction: column;
  background-color: var(--primary-white);
}

.d-flex {
  display: flex;
}

@import './typography.scss';

@import './components/nav-bar.scss';
@import './components/menu.scss';
//@import './components/about.scss';
@import './components/home-page.scss';
@import './components/casses.scss';
@import './components/project.scss';
@import './components/careers.scss';
@import './components/form.scss';
@import './components/contact.scss';
@import './components/input.scss';
@import './components/pricing.scss';
@import './components/404.scss';
@import'./components/staff-augment.scss';
@import'./components/end-to-end.scss';
