.cases {
  &__wrapper {
    @extend %wrapper;
    background-image: url('../../images/Home/Grain2.svg');
    background-color: #EFEFEF;
  }

  &__row-title{
    flex: none;
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 85px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 153px;
    align-items: center;
    justify-content: space-between;
  }

  &__row-options {
    flex: none;
    display: flex;
    flex-direction: row;
    height: auto;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
  }

  &__wrap-options {
    display: flex;
    width: calc(100% - 144px);
    height: auto;
    gap: 0.75em;
    flex-wrap: wrap;
  }

  &__text-options {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 157%;
    color: #111517;
    width: 144px;
    margin-top: 5px;
  }

  &__options-button {
    display: inline-block;
    height: auto;
    border-radius: 5px;
    border: 1px solid var(--grey-700);
    background: var(--grey-200);
    text-align: center;
    justify-content: center;
    padding-left: 13px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
  }

  &__options-button-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #626262;
  }

  &__row-dropdowns {
    flex: none;
    display: flex;
    width: 70%;
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-top;
  }

  @media screen and (min-width: 1720px) {
    &__row-dropdowns {
      width: 75%;
    }
  }

  &__title {
    @include h2;
    @include medium;
    color: var(--grey-1000);
  }

  &__column {
    flex: none;
    display: flex;
    width: 95%;
    height: auto;
  }

  &__column-not-found {
    width: 100%;
    height: auto;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    gap: 1em;
  }

  &__text-not-found {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 105%;
    display: flex;
    width: auto;
    color: #111517;
    height: auto;
  }

  &__subtext-not-found {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 157%;
    display: flex;
    color: #626262;
    height: auto;
    width: auto;
    max-width: 273px;
    text-align: center;
  }

  &__cards {
    margin-top: 50px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: grid;
    gap: 1rem;
    margin-bottom: 120px;
  }

  &__card {
    flex: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 1px dashed var(--grey-1000);
    flex-direction: column;
  }

  &__card:hover {
    background-color: #ebe9e9;
  }

  &__card-title-row{
    flex: none;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    width: 95%;
    height: auto;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 2.5%;
    justify-content: space-between;
  }

  &__card-title-row-2{
    flex: none;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
  }

  &__card-title-column {
    flex: none;
    display: flex;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 15px;
  }

  &__card-title{
    @include subtitle-2;
    @include bold;
    color: var(--grey-900);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    width: 38%;
  }

  &__card-subtitle{
    @include body-2;
    color: var(--grey-700);
    flex: none;
    margin-top: -5px;
    margin-bottom: 18px;
    width: 38%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  &__card-technologies{
    flex: none;
    display: flex;
    width: auto;
    min-width: 50%;
    max-width: 65%;
    gap: 0.5em;
    height: auto;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__technologies-button {
    display: inline-block;
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--grey-700);
    background: transparent;
    text-align: center;
    justify-content: center;
    padding-left: 18px;
    padding-right: 18px;
  }

  &__technologies-button-text {
    @include body-2;
    color: var(--grey-700);
    white-space: nowrap;
  }

  &__image-conteiner {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: auto;
    margin-top: -1.5%;
    margin-bottom: 1.5%;
    overflow: hidden;
    pointer-events: none;
  }

  &__image-main {
    width: 100%;
    border-radius: 10px;
  }

  &__select {
    flex: none;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 7px;
    border: 1px solid var(--grey-500);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
  }

  &__select-2 {
    flex: none;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 385px;
    border-radius: 7px;
    border: 1px solid var(--grey-500);
    flex-direction: column;
    align-items: center;
    gap: 1em;
    z-index: 3;
    background-image: url('../../images/Home/Grain2.svg');
    background-color: #EFEFEF;
  }

  &__select-row-title {
    flex: none;
    display: flex;
    width: 92.5%;
    height: 60px;
    margin-left: 5%;
    margin-right: 2.5%;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  &__select-row-main {
    flex: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }

  &__select-row {
    flex: none;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__select-row-main:hover {
    background-image: url('../../images/Home/Grain3.svg');
    background-color: #E1E1E1;
    cursor: pointer;
  }

  &__select-column {
    flex: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0em;
    height: 300px;
    overflow-y: auto;
  }

  &__select-column::-webkit-scrollbar {
    width: 10px;
  }

  &__select-column::-webkit-scrollbar-track {
    background: transparent;
  }

  &__select-column::-webkit-scrollbar-thumb {
    background-color: #111517;
    border-radius: 5px;
    border: 3px solid #EFEFEF;
  }

  &__select-title {
    @include button-text-small;
    color: var(--grey-1000);
  }

  &__select-subtitle {
    @include body-2;
    @include medium;
    display: flex;
    align-items: center;
    color: var(--grey-1000);
    flex: none;
  }

  &__link {
    text-decoration: none;
  }

    &__row-expand-1 {
      height: 60px;
      width: 32%;
      z-index: 5;
    }

    &__row-expand-2 {
      height: 60px;
      width: 32%;
      z-index: 4;
    }

    &__row-expand-3 {
      height: 60px;
      width: 32%;
      z-index: 3;
    }

  @media (min-width: 1024px) {
    &__cards {
      grid-template-columns: repeat(2, 1fr);
    }

    &__card-technologies {
      width: 20%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1085px){
    &__card-technologies {
      display: none;
    }

    &__card-title{
      width: 90%;
    }

    &__card-subtitle {
      margin-top: 10px;
      color: var(--grey-700);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      width: 90%;
    }
  }

  @media (min-width: 767px) and (max-width: 1024px) {
    &__card-technologies {
      display: flex;
    }
  }

  @media (max-width: 767px) {
    &__card-technologies {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px){
    &__card-title {
      color: var(--grey-900);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      width: 35%;
    }

    &__card-subtitle {
      margin-top: 5px;
      color: var(--grey-700);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    &__card-title-column {
      gap: 0em;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 1085px) and (max-width: 1250px){
    &__card-title {
      color: var(--grey-900);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      width: 35%;
    }

    &__card-subtitle {
      margin-top: 5px;
      color: var(--grey-700);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  @media screen and (min-width: 1250px) and (max-width: 1280px){
    &__card-title {
      color: var(--grey-900);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      width: 35%;
    }
    
    &__card-subtitle {
      margin-top: 5px;
      color: var(--grey-700);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1280px){
    &__row-title {
      flex-direction: column;
      height: 195px;
      align-items: flex-start;
    }

    &__row-dropdowns {
      width: 100%;
    }

    &__technologies-button-text {
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: var(--grey-700);
    }
  }

  @media screen and (min-width: 1250px) and (max-width: 1350px){
    &__technologies-button-text {
      font-size: 12px;
    }

    &__card-title {
      width: auto;
      font-size: 23px;
    }

    &__card-title-column {
      gap: 0em;
    }
  }

  @media screen and (min-width: 1350px) and (max-width: 1450px){
    &__technologies-button-text {
      font-size: 12px;
    }

    &__card-title {
      width: auto;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 767px){
    &__row-title {
      flex-direction: column;
      height: 275px;
      margin-top: 111px;
      align-items: flex-start;
      gap: 1em;
    }

    &__title {
      font-size: 45px;
    }

    &__cards {
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
    }

    &__row-options {
      flex-direction: column;
    }

    &__wrap-options {
      width: 100%;
    }

    &__row-dropdowns {
      flex-direction: column;
      width: 100%;
      height: 230px;
    }

    &__select {
      width: 100%;
    }
    &__select-2 {
      width: 100%;
    }

    &__card-title {
      color: var(--grey-900);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      width: 100%;
    }

    &__card-subtitle {
      width: 90%;
    }

    &__card-title-column {
      width: 100%;
      gap: 0em;
    }

    &__card-subtitle {
      margin-top: 5px;
      color: var(--grey-700);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin-top: 5px;
      margin-bottom: 0px;
    }

    &__card {
      border-radius: 10px;
      width: 100%;
    }

    &__row-expand-1 {
      height: 60px;
      width: auto;
      z-index: 5;
    }

    &__row-expand-2 {
      height: 60px;
      width: auto;
      z-index: 4;
    }

    &__row-expand-3 {
      height: 60px;
      width: auto;
      z-index: 3;
    }

  }
}


.text-vacancy {
  text-align: center;
  color: #000000;
  font-size: 14px;
  margin: 0;
}
