.form {
  &__vacancy-paper.MuiPaper-root.MuiPopover-paper {
    background-color: var(--magenta-400);
    background-image: url('../../images/noise.png');
    color: var(--primary-white);
  }

  &__container {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    background-color: var(--magenta-400);
    background-image: url('../../images/Home/Grain3.svg');
    display: flex;

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused,
    .MuiInputBase-input,
    * {
      color: var(--primary-white);
    }

    .MuiInputBase-root:before,
    .MuiInputBase-root:after,
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary-white) !important;
      border-width: 2px;
    }

    .MuiSelect-icon {
      top: unset;

      path {
        stroke: var(--primary-white);
      }
    }

    .MuiButtonBase-root,
    .MuiFormLabel-root {
      @include button-text-small;
      color: var(--primary-white);
    }

    .MuiFormLabel-root.MuiInputLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
      color: yellow;
    }
  }

  &__title {
    @include h3;
    @include medium;
    color: var(--primary-white);
    margin-bottom: 28px;
  }

  &__text {
    @include body-2;
    @include medium;
    color: var(--primary-white);
  }

  &__double-field {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    .MuiFormControl-root,
    .MuiButtonBase-root {
      //width: 100%;
      margin-bottom: 20px;
    }

    .MuiButtonBase-root {
      justify-content: space-between;
      border-color: var(--primary-white);
    }

    .MuiButtonBase-root:hover {
      border-color: var(--grey-400);
      background-color: transparent;
    }

    .MuiButton-contained {
      background-color: var(--primary-white);

      &:hover {
        background-color: var(--grey-300);
      }

      p {
        color: black;
      }
    }

    .MuiButtonBase-root.MuiButton-root {
      display: flex;
      justify-content: space-between;
      padding: 20px 25px;
    }
  }

  &__message.MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }

  &__left-section,
  &__right-section {
    width: 50%;
  }

  &__left-section {
    padding: 100px 9.7vw;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--white-opacity-40);

    &-top {
      height: 50%;
      border-bottom: 1px solid var(--white-opacity-40);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 calc(9.7vw - 1px);
    }
    &-bottom {
      height: 50%;
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: calc(9.7vw - 1px);
    }
  }

  &__hr {
    &-photo {
      height: 140px;
      width: 140px;
      border-radius: 100%;
      border: 6px solid var(--primary-white);
      margin-right: 20px;
    }

    &-info {
      .form__double-field {
        gap: 10px;
      }
    }

    &-contact {
      @include body-2;
      @include medium;
      display: flex;
      align-items: center;
      width: auto;
      text-transform: capitalize;
      text-decoration: none;
      gap: 5px;
      margin: 0;
      border: 1px solid var(--white-opacity-40);
      color: var(--primary-white);
      border-radius: 24px;
      padding: 10px;
    }

    &-name {
      @include subtitle-3;
      @include semi-bold;
      color: var(--primary-white);
    }

    &-position {
      @include body-2;
      margin-bottom: 20px;
      color: var(--primary-white);
    }
  }

  &__files {
    user-select: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin: 30px 0;

    &-file {
      @include body-2;
      margin-right: 8px;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--white-opacity-20);
        margin: 0 8px 8px 0;
        padding: 8px 8px 8px 12px;
        max-width: 150px;

        .MuiSvgIcon-root {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1558px) {
    &__left-section {
      padding: 94px 4.7vw;
    }
  }

  @media (max-width: 1279px) {
    &__container {
      flex-direction: column;
    }

    &__text {
      display: none;
    }

    &__left-section,
    &__right-section {
      width: auto;
    }

    &__left-section {
      padding: 56px 40px 28px;
      border-bottom: 1px solid var(--white-opacity-40);
    }

    &__right-section {
      padding: 0;
      flex-direction: row;

      &-top,
      &-bottom {
        padding: 28px 0 56px;
        flex: 1;
        border: none;
      }

      &-top {
        padding-left: 40px;
      }

      &-bottom {
        padding-right: 40px;
        justify-content: center;
        height: 100%;
        border-left: 1px solid var(--white-opacity-40);
      }
    }

    &__hr {
      &-photo {
        height: 100px;
        width: 100px;
        border: 4.5px solid var(--primary-white);
      }
    }
  }

  @media (max-width: 767px) {
    &__double-field {
      gap: 20px;
      flex-direction: column;
    }

    &__left-section {
      padding: 48px 24px 24px;
    }

    &__right-section {
      padding: 24px 24px 48px;
      display: flex;
      flex-direction: column;
      border-left: none;

      &-top,
      &-bottom {
        padding: 0;
        flex: 1;
        border: none;
      }
    }

    &__hr-info {
      .form__double-field {
        flex-direction: row;
      }
    }
  }
}
