
.magento {
  &-solutions__section {
    padding: 70px;
    background-color: var(--grey-900);
  }

  .sa_remote-teams {
    padding-bottom: 140px;
    background-color: var(--grey-900);
    border-radius: 16px 16px 0px 0px;
  }

  &-solutions__title-text {
    color: var(--primary-white);
    margin: 0;
    white-space: nowrap;

    &:nth-child(even) {
      margin-left: 16%;
    }
  }

  &-solutions__title-text {
    font-size: 44px;
    margin: 0;
  }

  &-solutions__title {
    padding: 75px;
  }

  &-solutions__remote-block {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    color: var(--primary-white);
    border-top: 1px solid var(--white-opacity-40);
    padding: 0px 30px 0px 0px;

    &:last-of-type {
      border-bottom: 1px solid var(--white-opacity-40);
    }
  }


  @media (max-width: 1280px) {
    &-solutions__title-text {
      font-size: 36px;
    }

    &-solutions__remote-block {
      padding-right: 15px;
      padding-left: 15px;
    }
  }


  @media (min-width: 768px) {
    &-solutions__arrows {
      display: none;
    }
  }

  @media (max-width: 768px) {
    &-solutions__section {
      margin: 0;
      padding: 20px;
    }
    &-solutions__arrows {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }

    &-solutions__next {
      margin-left: 20px;
    }

    .sa_remote-teams {
      padding-bottom: 100px;
    }

    &-solutions__title {
      padding: 10px;
    }

    &-solutions__title-text {
      font-size: 37px;
      margin-left: 0;

      &:nth-child(even) {
        margin-left: 22%;
      }
    }

    &-solutions__remote-block {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  @media screen and (min-width: 362px) and (max-width: 576px) {
    &-solutions__section {
      padding: 80px 0px 0px 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &-solutions__remote-blocks-wrap {
      padding-bottom: 80px;
    }

    &-solutions__arrows {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
      padding-right: 15px;
    }

    .sa_remote-teams {
      padding-bottom: 100px;
    }


    &-solutions__title {
      padding: 10px;
    }

    &-solutions__title-text {
      font-size: 30px;
      margin-left: 0;

      &:nth-child(even) {
        margin-left: 22%;
      }
    }

    &-solutions__remote-block {
      display: flex;
      justify-content: space-between;
      color: var(--primary-white);
      border-top: 1px solid var(--white-opacity-40);
      padding: 20px 20px 20px 0px;
    }

    &-solutions__remote-header {
      margin: 1px;
    }
  }
}
