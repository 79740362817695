/*
* You can also get these values inside JS with the following code:
* getComputedStyle(document.documentElement).getPropertyValue('--primary-white')
* this will return '#fdfdfd'
*/

:root {
  /* Primary */
  --primary-white: #fdfdfd;
  --primary-black: #111517;
  --primary-red: #ff655c;
  --primary-blue: #3c42da;

  /* Black-opacity */
  --black-opacity-80: rgba(17, 21, 23, 0.8);
  --black-opacity-60: rgba(17, 21, 23, 0.6);
  --black-opacity-40: rgba(17, 21, 23, 0.4);
  --black-opacity-20: rgba(17, 21, 23, 0.2);
  --black-opacity-10: rgba(17, 21, 23, 0.1);
  --black-opacity-4: rgba(17, 21, 23, 0.04);

  /* White-opacity */
  --white-opacity-80: rgba(255, 255, 255, 0.8);
  --white-opacity-60: rgba(255, 255, 255, 0.6);
  --white-opacity-40: rgba(255, 255, 255, 0.4);
  --white-opacity-20: rgba(255, 255, 255, 0.2);
  --white-opacity-10: rgba(255, 255, 255, 0.1);
  --white-opacity-4: rgba(255, 255, 255, 0.04);

  /* Magenta */
  --magenta-100: #f07188;
  --magenta-200: #ee617b;
  --magenta-300: #e0546f;
  --magenta-400: #d34260;
  --magenta-500: #c73455;
  --magenta-600: #bb2649;
  --magenta-700: #a82242;
  --magenta-800: #971f3b;
  --magenta-900: #8b283a;

  /* Blue */
  --blue-50: #eef4ff;
  --blue-100: #e0eaff;
  --blue-200: #c7d7fe;
  --blue-300: #a4bcfd;
  --blue-400: #8098f9;
  --blue-500: #6172f3;
  --blue-600: #444ce7;
  --blue-700: #3538cd;
  --blue-800: #2d31a6;
  --blue-850: #2d3295;
  --blue-900: #2d3282;

  /* Grey */
  --grey-50: #fcfcfd;
  --grey-100: #f5f5f5;
  --grey-200: #efefef;
  --grey-300: #e1e1e1;
  --grey-400: #bebebe;
  --grey-500: #9f9f9f;
  --grey-600: #767676;
  --grey-700: #626262;
  --grey-800: #434343;
  --grey-850: #2e2e2e;
  --grey-900: #222222;
  --grey-1000: #111517;

  /* Red */
  --red-50: #fef3f2;
  --red-100: #fee4e2;
  --red-200: #fecdca;
  --red-300: #fda29b;
  --red-500: #ff5641;
  --red-600: #e4443b;
  --red-700: #cc0a00;
  --red-800: #a30800;
  --red-900: #7a0600;
}
