.pricing {
  &__container{
    position: relative;
  }

  &__top-bg{
    width: 100%;
    height: 450px;
    left: 0px;
    top: 104px;
    background-image: url('../../images/noise.png');
    background-color: #434343;
  }

  &__choose_container{
    position: absolute;
    height: 500px;
    top: 184px;
  }

  &__bottom-bg{
    width: 100%;
    height: 950px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 40px;
    align-items: center;
  }
}
