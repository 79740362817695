.contact {
  &__wrapper {
    padding-top: 90px;
    min-height: unset;
  }

  &__vacancy-paper.MuiPaper-root.MuiPopover-paper {
    color: var(--primary-black);
    box-sizing: border-box;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-right: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    box-shadow: none;
  }

  &__white {
    color: var(--primary-white) !important;
    border-right: 2px solid white !important;
    border-left: 2px solid white !important;
    border-bottom: 2px solid white !important;
    background-color: var(--magenta-400) !important;
    background-image: url('../../images/Home/Grain3.svg');
  }

  &__file-wrap {
    border: 1px solid black !important;
  }

  &__form {
    background: none !important;

    .mail-icon path {
      color: red !important;
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused,
    .MuiInputBase-input,
    * {
      color: var(--primary-black) !important;
    }

    .MuiInputBase-root:before,
    .MuiInputBase-root:after,
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary-black) !important;
      border-width: 2px;
    }

    .MuiOutlinedInput-root,
    .MuiSelect-root {
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0.24em;
      font-size: 14px;
    }

    .MuiSelect-icon {
      top: unset;
      right: 25px;

      path {
        stroke: var(--primary-black) !important;
      }
    }

    .MuiButtonBase-root,
    .MuiFormLabel-root {
      @include button-text-small;
      color: var(--primary-black) !important;
      border-color: var(--primary-black) !important;
    }

    .MuiButton-contained {
      background-color: var(--primary-black) !important;

      &:hover {
        background-color: var(--primary-white);
      }

      p {
        color: var(--primary-white) !important;
      }
    }
  }

  &__upload_btn {
    .MuiButtonBase-root:hover {
      border-color: var(--primary-white) !important;

    }
  }

  &__text {
    color: black !important;
  }

  &__photo_border {
    border: 6px solid var(--primary-black) !important;
  }

  &__image-wrapper {
    position: relative;
    height: 140px;
    width: 140px;
    border-radius: 20px;
    margin-right: 20px;
  }

  &__image-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-image: url('../../images/noise.png');
    opacity: 0.3;
    z-index: 99999;
  }

  &__btn_border {
    border: 1px solid var(--black-opacity-40) !important;
  }

  &__right-section {
    border-left: 1px solid var(--black-opacity-40) !important;
  }

  &__top-section {
    border-bottom: 1px solid var(--black-opacity-40) !important;
  }

  &__input_wrapper {
    margin: 20px 0 40px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: 1558px) {
    &__text {
      font-size: 37px !important;
    }
  }

  @media (max-width: 1279px) {
    &__right-section {
      border-top: 1px solid var(--black-opacity-40) !important;
    }
    &__top-section {
      border-right: 0 !important;
      border-bottom: 0 !important;
      height: 100%;
    }
    &__image-wrapper {
      height: 100px;
      width: 100px;
    }
    &__right-section-bottom {
      border-left: 1px solid var(--black-opacity-40) !important;
    }
  }

  @media (max-width: 767px) {
    &__right-section {
      border-top: 1px solid var(--black-opacity-40) !important;
      border-left: none !important;
    }
    &__top-section {
      border: none !important;
    }
    &__right-section-bottom {
      border-left: 0 !important;
    }
  }
}


.overlay {
  background-color: rgba(0,0,0,0.5);
  position: relative;
  height: 20px;
  width: 20px;
}

.loader {
  position: absolute;
  top: 1%;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid white;
  border-right-color: transparent;
  transform-origin: center;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}