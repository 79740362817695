@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Zona Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/ZonaPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Zona Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/ZonaPro-RegularItalic.ttf') format('truetype');
}
