@import './styles/main.scss';

.menuOpened {
  height: calc(100vh - 98px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .menuOpened {
    height: calc(100vh - 68px);
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.grey-section-promo {
  background-color: #f4f4f6;
  border: 40px solid white;
  border-top: none;
  min-height: calc(100vh - 100px);
}

.grey-section {
  background-color: #f4f4f6;
  border: 40px solid white;
  border-top: none;
}

.grey-section-about-promo {
  background-color: #f4f4f6;
  border: 40px solid white;
  border-top: none;
  min-height: calc(100vh - 100px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .grey-section-promo {
    min-height: calc(100vh - 68px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .grey-section-promo {
    min-height: calc(100vh - 98px);
  }
}

.grey-section-bottom {
  background-color: #f4f4f6;
  border: 40px solid white;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .container {
    max-width: 90%;
  }

  .grey-section-promo {
    border: 8px solid white;
    border-top: none;
  }

  .grey-section-about-promo {
    border: 8px solid white;
    border-top: none;
  }

  .grey-section {
    border: 8px solid white;
    border-top: none;
  }

  .grey-section-bottom {
    border: 8px solid white;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }

  .grey-section-promo {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section-about-promo {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section-bottom {
    border: 20px solid white;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1200px) {
  .grey-section-about-promo {
    min-height: calc(80vh - 100px);
    height: calc(80vh - 100px);
  }
}

@media screen and (min-height: 1201px) and (max-height: 1400px) {
  .grey-section-about-promo {
    min-height: calc(70vh - 100px);
    height: calc(70vh - 100px);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .container {
    max-width: 1000px;
  }

  .grey-section-promo {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section-about-promo {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section {
    border: 20px solid white;
    border-top: none;
  }

  .grey-section-bottom {
    border: 20px solid white;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .container {
    max-width: 1100px;
  }

  .grey-section-promo {
    border: 30px solid white;
    border-top: none;
  }

  .grey-section-about-promo {
    border: 30px solid white;
    border-top: none;
  }

  .grey-section {
    border: 30px solid white;
    border-top: none;
  }

  .grey-section-bottom {
    border: 30px solid white;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
  .container {
    max-width: 1200px;
  }

  .grey-section-promo {
    border: 40px solid white;
    border-top: none;
  }

  .grey-section-about-promo {
    border: 40px solid white;
    border-top: none;
  }

  .grey-section {
    border: 40px solid white;
    border-top: none;
  }

  .grey-section-bottom {
    border: 40px solid white;
  }
}

.clutch-widget {
  position: relative;
  top: 50px;
  left: 50px;
}

.goodfirm-widget {
  position: relative;
  left: 50px;
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
  .clutch-widget {
    left: 20px;
  }

  .goodfirm-widget {
    left: 15px;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
  .clutch-widget {
    left: 40px;
  }

  .goodfirm-widget {
    left: 40px;
  }
}

img {
  user-select: none;
}
