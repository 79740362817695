.footerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
}

.title {
  display: none;
  padding-bottom: 20px;
}

.info {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0%;
}

.infoBoxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.infoBox {
  width: 24%;
  font-family: Manrope;
  font-style: normal;
  line-height: 156%;

  color: white;
}

.infoBox2 {
  display: none;
  width: 24%;
  font-family: Manrope;
  font-style: normal;
  line-height: 156%;

  color: white;
}

.infoTitle {
  font-weight: 500;
  font-size: 18px;
}

.infoBox ul {
  margin-left: 0;
  padding-left: 0;
}

.infoBox li {
  list-style-type: none;
  font-weight: normal;
  font-size: 14.4px;
  letter-spacing: 0.01em;

  color: white;
}

.infoBox a {
  text-decoration: none;
  color: white;
}

@media (hover: hover) {
  .infoBox:last-child li:hover {
    transform: scale(1.02);
    transition-duration: 150ms;
  }
}

.imgLinks {
  width: 27%;
  display: flex;
  justify-content: space-between;
}

.imgLinks a {
  margin: 0 3%;
}

.imgLinks a:nth-last-child(1) {
  margin-left: 3%;
  margin-right: 0;
}

@media (hover: hover) {
  .imgLinks img:hover {
    transform: scale(1.3);
    transition-duration: 150ms;
  }
}

.hrGrey {
  background: var(--white-opacity-20);
  height: 1px;
  border: none;
  width: 100%;
}

.bottomItems {
  padding-top: 16px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: white;
}

.bottomItems span {
  white-space: nowrap;
  text-decoration: none;
  padding-right: 18px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 146%;

  padding-right: 5%;

  color: white;
}

@media screen and (min-width: 1720px) {
  .title {
    display: flex;
    margin-left: 5%;
  }

  .info {
    width: 90%;
    align-self: center;
    padding-top: 30px;
  }

  .hrGrey {
    width: 90%;
  }

  .bottomItems {
    margin-left: 5%;
    width: 35%;
    padding-bottom: 56px;
  }

  .bottomItems span {
    padding-right: 10%;
  }

  .imgLinks {
    width: 24%;
    display: flex;
    justify-content: space-between;
  }
}

@media (hover: hover) {
  .bottomItems a:hover {
    transform: scale(1.02);
    transition-duration: 150ms;
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .container {
    padding-bottom: 2%;
    width: 94%;
    margin: auto;
  }

  .footer {
    padding: 2% 0;
  }

  .footerWrap {
    width: 94%;
  }

  .title {
    display: none;
    justify-content: center;
    margin-top: 2%;
  }

  .hrGray {
    width: 94%;
    margin: 0 auto 3%;
  }

  .info {
    width: 100%;
    flex-wrap: wrap;
    padding-top: 30px;
  }

  .infoBoxes {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .infoBox {
    width: 45%;
    white-space: normal;
  }

  .infoBox2 {
    display: flex;
    width: 45%;
    white-space: normal;
    flex-direction: column;
    gap: 1em;
  }

  .row {
    width: 100%;
    display: flex;
    flex: none;
    flex-direction: row;
    gap: 2em;
  }

  .infoBox:last-child {
    width: 50%;
  }

  .infoTitle .infoBox:nth-last-child(1) {
    width: 150px;
  }

  .hrGrayMobile {
    display: inline-flex;
    background: #dddfe4;
    height: 1px;
    border: none;
    width: 94%;
    margin: 3% auto 5%;
  }

  .imgLinks {
    display: none;
    padding-top: 10%;
    width: 100%;
    justify-content: center;
  }

  .bottomItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 56px;
  }

  .bottomItems span {
    font-size: 9px;
    color: white;
    white-space: nowrap;
    padding-right: 0;
  }
}

@media (min-width: 600px) and (max-width: 699px) {
  .infoBox {
    width: 43%;
  }

  .infoBox2 {
    display: flex;
    width: 43%;
    white-space: normal;
    flex-direction: column;
    gap: 1em;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  .infoBox {
    width: 39%;
  }

  .infoBox2 {
    display: flex;
    width: 39%;
    white-space: normal;
    flex-direction: column;
    gap: 1em;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1580px) {
  .imgLinks {
    width: 50px;
    display: none;
    justify-content: flex-end;
  }

  .infoBox {
    display: flex;
    width: 19%;
    white-space: normal;
    flex-direction: column;
    gap: 0.5em;
  }

  .infoBoxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 92.5%;
  }

  .infoBox2 {
    display: flex;
    width: 5%;
    white-space: normal;
    flex-direction: column;
    gap: 1.25em;
  }

  .row {
    width: auto;
    display: flex;
    flex: none;
    flex-direction: row;
    gap: 1.5em;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .footerWrap {
    width: 96%;
    margin: auto;
  }

  .title {
    display: none;
  }

  .infoTitle {
    font-size: 16px;
    font-weight: 600;
  }

  .hrGray {
    width: 100%;
    margin: 0 auto 3%;
  }

  .info {
    width: 100%;
    padding-bottom: 5%;
  }

  .infoBoxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .infoBox {
    width: 33%;
    white-space: normal;
  }

  .infoBox2 {
    display: flex;
    width: 33%;
    white-space: normal;
    flex-direction: column;
    gap: 0.5em;
  }

  .row {
    width: 100px;
    display: flex;
    flex: none;
    flex-direction: row;
    gap: 2em;
  }

  .imgLinks {
    width: 50px;
    display: none;
    justify-content: flex-end;
  }

  .imgLinks a {
    padding: 0 10%;
  }

  .imgLinks a:nth-last-child(1) {
    padding-left: 10%;
    padding-right: 0;
  }

  .bottomItems {
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    padding-bottom: 5%;
    padding-top: 3%;
  }

  .bottomItems span {
    font-size: 9px;
    color: white;
    white-space: nowrap;
    padding-right: 5%;
  }
}
