.careers {
  &__wrapper {
    @extend %wrapper;
    padding-top: 90px;
    min-height: unset;

    * {
      box-sizing: border-box;
      @include remove-pm;
    }

    .about__button {
      background-color: transparent;
    }

    @media (max-width: 1279px) {
      &.offer {
        flex-direction: column-reverse;
      }
    }

    @media (max-width: 767px) {
      padding-top: 68px;
    }
  }

  &__content,
  &__offer-content {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10%;
    margin: 56px 0 56px;
  }

  &__title {
    @include h2;
    @include medium;
    color: var(--grey-1000);
  }

  &__subtitle {
    color: var(--grey-700);
    align-self: flex-end;
    width: 50%;
  }

  &__offers-wrap {
    padding-bottom: 80px;
  }

  &__offers {
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    margin: 0 -2.5%;
  }

  &__card {
    text-decoration: none;
    display: inline-block;
    width: calc((100vw / 3) - 80px);
    min-height: 414px;
    padding: 40px;
    border: 1px dashed var(--black-opacity-80);
    border-radius: 16px;
    white-space: initial;
    margin-right: 1%;

    &:first-of-type {
      margin-left: 2.5%;
    }

    &:last-of-type {
      margin-right: 2.5%;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    &-title {
      @include subtitle-3;
      @include semi-bold;
      color: var(--grey-900);
      width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      border: 1px solid var(--grey-600);
      border-radius: 200px;
    }

    &-responsibilities {
      list-style: inside '— ';
      @include body-2;
      color: var(--grey-700);
    }

    &-controls {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 38px;

      .MuiButtonBase-root.MuiButton-root {
        padding: 11px;
        color: var(--grey-1000);
        border: 1px solid var(--grey-600);
        border-radius: 6px;
        min-width: 48px;
      }

      .MuiButtonBase-root.MuiButton-root:disabled {
        color: var(--grey-400);
        border: 1px solid var(--grey-400);
      }
    }
  }

  @media (max-width: 1279px) {
    &__content {
      width: auto;
      margin: 0;
    }

    &__header {
      gap: 16px;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding: 64px 40px 48px;
    }

    &__subtitle {
      width: auto;
    }

    &__offers {
      height: auto;
      margin: 0;
    }

    &__card {
      width: calc((100vw / 2) - 48px);
      height: auto;
      padding: 24px;
      margin-right: 3%;

      &:first-of-type {
        margin-left: 24px;
      }

      &:last-of-type {
        margin-right: 24px;
      }

      &-link {
        border: none;
      }
    }
  }

  @media (max-width: 767px) {
    &__card {
      width: calc(100vw - 48px);
      margin-right: 4%;
    }

    &__header {
      padding: 64px 24px 48px;
    }
  }

  &__offer {
    &-content {
      width: 50%;
      padding-bottom: 48px;
      margin-top: -250px;
    }

    &-header {
      margin: 64px 0 80px;
    }

    &-title {
      margin-bottom: 5px;
    }

    &-subtitle {
      display: flex;
      align-items: center;
    }

    &-time {
      padding-left: 10px;
      font-weight: 400;
    }

    &-section {
      margin-bottom: 40px;

      &-title {
        margin-bottom: 24px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-list {
      &-item {
        display: flex;
        gap: 10px;
        align-items: center;
        min-height: 32px;
        margin-bottom: 5px;
      }
    }

    @media (max-width: 1900px) {
      &-content {
        margin: -250px 2.5% 0;
      }
    }

    @media (max-width: 1279px) {
      &-content {
        margin: 0;
        width: 100%;
        padding: 64px 40px 48px;
      }

      &-header {
        margin: 0 0 40px;
      }
    }

    @media (max-width: 767px) {
      &-content {
        padding: 64px 24px 48px;
      }

      &-subtitle {
        flex-direction: column;
        align-items: flex-start;
      }

      &-time {
        padding-left: 0;
      }
    }
  }

  &__action {
    &-wrap {
      top: 138px;
      right: 5%;
      position: sticky;
      width: 434px;
      border: 1px solid var(--black-opacity-10);
      padding: 32px;
      margin-left: auto;
      margin-bottom: 40px;
    }

    &-block {
      display: flex;
      flex-direction: column;
    }

    &-button-wrap {
      padding: 20px 28px;
      background: var(--magenta-400);
      text-decoration: none;
      background-image: url('../../images/noise.png');
      background-position: 100%;
      transition: background 900ms ease-in-out;
      border-radius: 6px;

      &:hover {
        background-position: 0;
      }
    }

    &-button {
      @include button-text-small;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: var(--primary-white);
    }

    &-hr {
      margin: 32px 0;
      height: 1px;
      width: 100%;
      border: none;
      background: var(--black-opacity-10);
    }

    &-share {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: var(--grey-900);

      .react-share__ShareButton {
        margin-right: 15px;
        cursor: pointer;

        path {
          fill: var(--grey-1000);
        }

        &:hover {
          transform: scale(1.3);
          transition-duration: 150ms;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (max-width: 1279px) {
      &-wrap {
        position: initial;
        width: auto;
        margin: 0 8px 40px;
      }
    }
  }
}
